import $ from 'jquery';

export default function init () {
  $('.carousel').carousel();

  $('.lead-carousel').on('slid.bs.carousel', function() {
    var currentIndex = $('.item.active').index();
    $('.hero-tabs li').removeClass('active');
    $('.hero-tabs li').eq(currentIndex).addClass('active');
  });

  $('#carousel-secondary').carousel({
    interval: false
  });
  $('#carousel-testimonial').carousel({
    interval: false
  });
    
  $('.hero-tabs>li a h4').matchHeight()
};
