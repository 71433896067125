if (navigator.userAgent.match(/MSIE 9/) !== null || navigator.userAgent.match(/MSIE 10/) !== null || navigator.userAgent.match(/Trident/) !== null) {
  // Moved object assign outside of document ready for comparison.
  if (typeof Object.assign != 'function') {
    Object.assign = function (target, varArgs) { // .length of function is 2
      'use strict';
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    };
  }

  //do IE specific code
  $(function () {
    if (!String.prototype.includes) {
      String.prototype.includes = function () {
        'use strict';
        return String.prototype.indexOf.apply(this, arguments) !== -1;
      };
    }

    /* Placeholder */
    $('[placeholder]').focus(function() {
      var input = $(this);
      if (input.val() == input.attr('placeholder')) {
        input.val('');
        input.removeClass('placeholder');
      }
    }).blur(function() {
      var input = $(this);
      if (input.val() == '' || input.val() == input.attr('placeholder')) {
        input.addClass('placeholder');
        input.val(input.attr('placeholder'));
      }
    }).blur();

    $('[placeholder]').parents('form').submit(function() {
      $(this).find('[placeholder]').each(function() {
        var input = $(this);
        if (input.val() == input.attr('placeholder')) {
          input.val('');
        }
      })
    });
  });
}

if (navigator.userAgent.match(/Safari/) !== null) {
  if (typeof Object.assign != 'function') {
    Object.assign = function (target, varArgs) { // .length of function is 2
      'use strict';
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    };
  }
}

// Needed in mobile and Safari < 9.
if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}
function greenBandContactMe(modalId) {
  alert(modalId);
}
import logfix from '../../_resources/modules/logfix/logfix.js'
import header from '../../_resources/modules/header/header.js';
import carousel from '../../_resources/modules/carousel/carousel.js';
import herotabs from '../../_resources/modules/hero-tabs/hero-tabs.js';
import dropdowntabs from '../../_resources/modules/dropdown-tabs/dropdown-tabs.js';
import masonry from '../../_resources/modules/masonry/masonry.js';
import modal from '../../_resources/modules/modal/modal.js';
import interstitial from '../../_resources/modules/interstitial/interstitial.js';
import { tools, updateLanguage } from '../../_resources/modules/tools/tools.js';

import { compare, compareNow, hasCompared, getPlanData } from '../../_resources/modules/compare-plans/compare-plans.js';
import { personalizer } from '../../_resources/modules/personalizer/personalizer.js';
import { Plans } from './plans.js';
import { Product, products } from './product.js';
import { quotes } from './quotes.js';
import forms from '../../_resources/modules/forms/forms.js';
import tabs  from '../../_resources/modules/tabs/tabs.js';
import listGroup  from '../../_resources/modules/list-group/list-group.js';
import { addMap } from './map.js';

if (document.getElementById("gmap") != null) {
  addMap();
}

function formatPlanName(name) {
  var formattedName = name.replace(/ /g, "-").toLowerCase();

  return formattedName;
};

// Get plan data outside of document ready so IE completes AJAX ahead of calling compare().
if ($('#planCompare').length > 0) {
  getPlanData();
}

// Hide click to chat if it's disabled via AdminConfig.
if (!EnableLiveChat) {
  $('.start-chat-list-item').remove();
  $('.tools .tool#chatTool').css("display","none");

}

// Updates if Learn more is visible in hero tabs when zoomed.
function updateNavTabs() {
  var $navTabs = $('.hero-tabs').find('.footer').children();
  if (window.innerWidth > 1024) {
    $navTabs.removeClass('text-center');
  }
  // screen.deviceXDPI not defined in FF and Chrome.
  // screen.deviceXDPI define in IE.
  if (!screen.deviceXDPI && window.devicePixelRatio) {
    if (window.devicePixelRatio >= 2.2 && window.outerWidth > 1024 && $navTabs.hasClass('hidden-lg')) {
      $navTabs.removeClass('hidden-lg');
    } else if(window.devicePixelRatio <= 2 && window.outerWidth > 1024 && !$navTabs.hasClass('hidden-lg')) {
      $navTabs.addClass('hidden-lg');
    }
  } else if (screen.deviceXDPI && !window.devicePixelRatio) {
    if (screen.deviceXDPI >= 120 && window.outerWidth > 1024) {
      $navTabs.removeClass('hidden-lg');
    } else if (screen.deviceXDPI <= 96 && !$navTabs.hasClass('hidden-lg')) {
      $navTabs.addClass('hidden-lg');
    }
  }
}

$(window).on('resize', function() {
  $.fn.matchHeight._update();
  $.fn.matchHeight._applyDataApi();
  if ($('.hero-tabs').length > 0) {
    updateNavTabs();
  }
});

$(function () {
  if ($('.hero-tabs').length > 0) {
    updateNavTabs();
  }

  function getMinMaxDates(years) {
    var calculateDate = new Date();
    var newDate = calculateDate.getFullYear() - years;
    calculateDate.setFullYear(newDate);
    return calculateDate;
  }

  /* Quote Datepicker */
  $("#quote-datepicker").datepicker({
    altFormat: "yy/mm/dd",
    prevText: "",
    nextText: "",
    changeYear: true,
    yearRange: "-65:+0",
    minDate: getMinMaxDates(65),
    maxDate: getMinMaxDates(18),
    altField: "#quote-form input[name='dob']",
    onSelect: function() {
      $("#quote-form button#birthdate").dropdown("toggle");
    }
  });
  $("#quote-datepicker").datepicker('setDate', null);

  /* Compare Costs Datepicker */
  $("#costs-datepicker").datepicker({
    altFormat: "yy/mm/dd",
    prevText: "",
    nextText: "",
    changeYear: true,
    yearRange: "-100:+0",
    minDate: getMinMaxDates(100),
    maxDate: getMinMaxDates(0),
    altField: "#compare-costs input[name='dob']",
    onSelect: function() {
      $("#compare-costs button#birthdate").dropdown("toggle");
    }
  });
  $("#costs-datepicker").datepicker('setDate', null);

  if($('html').attr("lang") == "zh-hant"){
    $.datepicker.setDefaults($.datepicker.regional["zh-HK"]);
  }

  if ( $('input[name="dob-dates-min"]').exists() ) {
    var today = new Date();
    var ageOld = new Date($('input[name="dob-dates-min"]').val());
    var maxAge = today.getFullYear() - ageOld.getFullYear();
    var yearRange = "-" + maxAge + ":+0";
    $("#quote-datepicker").datepicker('option', 'minDate', ageOld);
    $("#quote-datepicker").datepicker('option', 'yearRange', yearRange);
    //$("#costs-datepicker").datepicker('option', 'minDate', ageOld);
    //$("#costs-datepicker").datepicker('option', 'yearRange', yearRange);
  }
  if ( $('input[name="dob-dates-max"]').exists() ) {
    var ageYoung = new Date($('input[name="dob-dates-max"]').val());
    $("#quote-datepicker").datepicker('option', 'maxDate', ageYoung);
    //$("#costs-datepicker").datepicker('option', 'maxDate', ageYoung);
  }

  header();
  carousel();
  tools();
  compareNow();
  herotabs();
  dropdowntabs();
  masonry();
  modal();
  interstitial();
  personalizer();
  forms();
  tabs();
  listGroup();

  $('.video-play').click(function(){
    $(this).hide();
    var target = $(this).attr('data-target');
    $(target).show();
  });

  $('.lightbox').on('shown.bs.modal', function () {
    $('body > .modal-backdrop').addClass('lightbox-backdrop');
  });

  $('[data-close]').on('click', function(event) {
    var targetClass = $( this ).data('close'),
        $targetSelector = $('.' + targetClass);

    $targetSelector.hasClass('isShowing') ?
      $targetSelector.removeClass('isShowing') :
      $targetSelector.hide();

  });

  if ($('.coverage-details').exists()) {
    $(".coverage-details .col-sm-8 .row .col-sm-6").matchHeight();
  }

});
