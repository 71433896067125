import $ from 'jquery';

export default function init () {
  $('.tabs-container .list-item a').click(function (e) {
    //e.preventDefault();
    $(".tabs-container .dropdown-label").text($(this).text());
    $('.tabs-container .list-item a').parent().removeClass("active");
    $(this).parent().addClass("active");
    $(this).tab('show');
  });
  $(".tabs-container .dropdown-menu li a").click(function() {
    $(".tabs-container .dropdown-label").text($(this).text());
    $(this).tab('show');
  });

  $(".tabs-container > .row > div").matchHeight();

  $(".tabs-container a[data-toggle='tab']").click(function(){
    var $root = $('html, body');
    $root.animate({
      scrollTop: $( ".tabs-content-container" ).offset().top - 100
    }, 500);
  });
}
