export var Plans = Plans || {};

Plans.commonMethods = {
  planNamesToIds: function(name) {
    var planName = name; 
    return planName.replace(/ /g, '-').toLowerCase();
  },
  lookupPlan: function(lookup) { 
    var planObj;

    Plans.category.data.forEach( function( v, i, a ) { 
      if ( lookup == v.id) {
        planObj = v;
      }
    });
    
    return planObj;
  }
};