import { updateLanguage } from '../tools/tools.js';

function toggleSection (section, show) {
  var isMobile = $('.navbar-toggle').is(':visible');

  if (!isMobile) {
    if (show) {
      section.addClass('open');
    } else {
      section.removeClass('open');
    }
    setSubSectionHeight(section.find('.nav-subsection'));
    $.fn.matchHeight._update();
  }
}; 

function expandSubSectionContentGroup (element) {
  var menuItem = element.closest('.list-group-item');
  var nodeID = element.data('contentNode');
  var subsection = element.closest('.nav-subsection');

  menuItem.siblings('.list-group-item.active').removeClass('active');
  menuItem.addClass('active');
  subsection.find('.nav-subsection-content-group').removeClass('active');
  $('#' + nodeID).addClass('active');
  setSubSectionHeight(subsection);
};

function collapseSubSectionContentGroup (element) {
  var menuItem = element.closest('.list-group-item');
  var subsection = element.closest('.nav-subsection');
  var contentGroup = subsection.find('.nav-subsection-content-group');

  menuItem.removeClass('active');
  contentGroup.removeClass('active');
  contentGroup.first().addClass('active');
  setSubSectionHeight(subsection);
};

function navSectionShow (section) {
  section.find('.dropdown-toggle').addClass('is-open');
  section.siblings().not('.nav-close-bar').find('.dropdown-toggle').addClass('is-open');
  //$("#main-row").css("overflow","hidden");
  //$("#main-row").css("position","fixed");
  // Hack - find css way to do this
  var pos = section.find('.dropdown-togglee > .section-label > .arrow').offset();
  var top = parseInt(pos.top) - 10;
  if($('.navbar-hk .dropdown.nav-section.open').hasClass("util")) {
    $('.floating-arrow').hide();
  }
  $.fn.matchHeight._update();
};

function navSectionHide (section) {
  section.find('.dropdown-toggle').removeClass('is-open');
  //$("#main-row").css("overflow","");
  //$("#main-row").css("position","");
  section.siblings().not('.nav-close-bar').find('.dropdown-toggle').removeClass('is-open');
  $('.floating-arrow').hide(); // Hack - find css way to do this
};

function setSubSectionHeight (subsection) {
  var menu = subsection.find('.nav-subsection-menu');
  var content = subsection.find('.nav-subsection-content');
  menu.height('auto');
  menu.height(Math.max(menu.height(), content.height()));
  $.fn.matchHeight._update();
};

function setLanguage() {
  var $lang = $("html").attr("lang");

  if($lang = 'en' || location.href.includes('localhost')) {
    // Set en-us as selected language.
    $('[data-lang="en"]').addClass('is-selected');

  } else {
    // Set zh-hk as selected language
    $('[data-lang="zh-hant"]').addClass('is-selected');

  }
}

function changeLanguage (event) {
  event.preventDefault();
  var $this = $( this ),
      lang = $this.data('lang'),
      isSelected = $this.hasClass('is-selected');

  if (!isSelected) {
    $this.addClass('is-selected');
    $this.parents('.change-lang-item')
      .siblings()
      .find('.is-selected')
      .removeClass('is-selected');
  }

  // Handle redirect to page in diff language here.

}

export default function init () {
  setLanguage();

  $('.navbar-nav > li > a.dropdown-toggle').each(function(){
    var hrefr = $(this).attr('href');

    $(this).find('.section-label').click(function(){
      if(hrefr != null && hrefr.indexOf('#') === -1){
        window.location.href = hrefr;
        return false;
      }
    });
    $(this).attr('href', 'javascript: void(0)');
  });

  $(".nav-close").attr('href',"javascript: void(0)");
  /* Our insurance solutions mobile accordion link fix */
  $('#accordion-our-insurance-solutions .accordion-toggle').each(function(){
  var hrefr = $(this).find('p').attr('target-url');

  $(this).find('p').click(function(){
    if(hrefr.indexOf('#') === -1){
      window.location.href = hrefr;
      return false;
    }
  });
  var $lang = $("html").attr("lang");
  if($lang != "en"){
    $(".search-form-xs input").attr("placeholder","你在找什麼？");
  }
});

  /* Carousel CTA 'Learn More' event */
  $('.cta-mobile-link').on('click', function() {
    var hrefr = $(this).attr('data-href');
    window.location.href = hrefr;
    return false;
  });
  /* End Carouse CTA event */


  $('[data-content-group-action="show"]').on('click', function (e) {
    e.preventDefault();
    expandSubSectionContentGroup($(e.currentTarget));
  });

  $('[data-content-group-action="hide"]').on('click', function (e) {
    e.preventDefault();
    collapseSubSectionContentGroup($(e.currentTarget));
  });

  /* dr */
  var timeoutId;
  $('.navbar-hk .nav-section').on({

    mouseenter: function (e) {
      $('.nav-subsection-content-group:first-child').addClass('active');
      timeoutId = setTimeout(function() {
        toggleSection($(e.currentTarget), true);
      }, 250);
    },
    mouseleave: function (e) {
      clearTimeout(timeoutId);
      toggleSection($(e.currentTarget), false);
      $('.nav-subsection-content-group').removeClass('active');
      $('.nav-subsection-menu .list-group-item').removeClass('active');
    },
    click: function(e) {
      var $this = $(this);
      var $navLink = $this.children('a');
      $.fn.matchHeight._update();
      if ( !$navLink.attr("href") == null ) {
        if ( $navLink.attr("href").length > 0 && $navLink.attr("href") != "#" ) {
          document.location.assign($navLink.attr("href"));
        }
      }
    },
    'show.bs.dropdown': function (e) {
      navSectionShow($(e.currentTarget));
    },
    'hide.bs.dropdown': function (e) {
      navSectionHide($(e.currentTarget));
    },
    'shown.bs.dropdown': function (e) {
      // Hack - find css way to do this
      var scrollY = $(window).scrollTop();
      var offset = $('.open .dropdown-togglee > .section-label > .arrow').offset();
      if($('.navbar-hk .dropdown.nav-section.open').hasClass("util")) {
        $('.floating-arrow').hide();
      }
      else {
        $('.floating-arrow').css({'top': offset.top - scrollY + 9}).show();
      }
    }
  });

  $(document).on('click', '.navbar-hk .dropdown-menu', function (e) {
    if ($(e.target).parents('.nav-subsection-container').length > 0) {
      e.stopPropagation();
    }
  });

  $(document).on('click', '.ui-datepicker-prev, .ui-datepicker-next, .ui-datepicker-year, .ui-datepicker-calendar', function (e) {
    e.stopPropagation();
  });
};
