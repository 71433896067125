export function personalizer() {

  var $personalizerData = $('[data-personalize]'),
      $personalizerOption = $('.personalizer-option'),
      personalizerState = {};

  // Initialize personalizerState object.
  // Initial state is never valid.
  // Once all options selected set personalizerState.isValid to true.

  (function(){
    personalizerState.isValid = false;
    personalizerState.questions = [];

    $('.personalizer-option').each(function() {
      personalizerState.questions.push($(this).find('.dropdown-label').text());
    });

    personalizerState.dialect = document.documentElement.lang;

  }());

  // Function doesSelectionWrap:
  // Determines if selected item from dropdown selection will wrap or not.
  // 30 character length works for now.

  var doesSelectionWrap = function (value) {
    return value.length > 30 ? true : false;
  }

  // Function capitalize:
  // Returns str but with first character capitalized.

  var capitalize = function(str) {

    var firstChar = str.charAt(0).toUpperCase(),
        remainder = str.substr(1, str.length);

    return firstChar + remainder;

  }

  // Function getCheckedBoxesAsArray:
  //
  // If checkbox selection present call this to get all checked answers in an array.
  // Array of checked items is returned.
  //
  // @param filter => $( "input[type='checkbox']:checked" )
  //        could preface selector param with parent form / form field.

  var getCheckedBoxesAsArray = function(filter) {
    var $selector = filter,
        arr = [];

    $selector.each(function() {
      console.log($( this ).val());
      arr.push($( this ).attr('name'));
    });

    return arr;
  };

  // Handling custom checkboxes.
  // Function:
  // Adds / removes checked attribute from checkbox based on attr passed usually is -> $( this ).

  var toggleCheckedAttr = function(ele) {

    var $checkboxLabel = ele;
      if ($checkboxLabel.prev('input').prop('disabled') != true) {
        if (($checkboxLabel.prev('input').prop('checked') == false) && ($checkboxLabel.prev('input').val() == "dependents=1")) {
          $('.hk-dropdown-list input').each(function() {
            $(this).prop('disabled', function(i, v) { return !v; });
            $(this).next().addClass("disabled");
          });
          $('.hk-dropdown-list input').first().removeAttr('disabled');
          $('.hk-dropdown-list input').first().removeClass('disabled');
        }
        else if ($checkboxLabel.prev('input').prop('checked') == true && $checkboxLabel.prev('input').val() == "dependents=1") {
          $('.hk-dropdown-list input').each(function() {
            $(this).removeAttr('disabled');
            $(this).next().removeClass('disabled');
          });
        }
        if (($checkboxLabel.prev('input').prop('checked') == false) && ($checkboxLabel.prev('input').val() != "dependents=1")) {
            $('.hk-dropdown-list input').first().prop('disabled',true);
            $('.hk-dropdown-list input').first().next().addClass('disabled');
        }
      }



    if ($checkboxLabel.prev('input').prop('disabled') != true) {

      if($checkboxLabel.prev('input').prop('checked') == false) {
        $checkboxLabel.prev('input').prop('checked', true);
        validate($(ele).parents('.personalizer-option'), false);
      } else {
        $checkboxLabel.prev('input').prop('checked', false);
        validate($(ele).parents('.personalizer-option'), true);
      }
      if ($('.hk-dropdown-list input:checked').length == 0) {
          $('.hk-dropdown-list input').first().prop('disabled',false);
          $('.hk-dropdown-list input').first().next().removeClass('disabled');
      }
    }
  }

  $personalizerOption.on('click', '[data-personalize]', function(event) {

    var $label = $( this ).parents('.personalizer-option'),
        $buttonDropdown = $label.find('button');
    //console.log(event);
    if (event.target.nodeName != 'LABEL') {

      // If the answer is long make the dropdown skinny.
      // Else condition handles if it's been added already.

      if (doesSelectionWrap(event.target.textContent)) {

        $buttonDropdown.addClass('btn-skinny');

      } else {

        $buttonDropdown.removeClass('btn-skinny');

      }

      $buttonDropdown.children('.dropdown-label').text( event.target.textContent );

    }

    return validate(event);

  });

  $('.checkbox-dropdown .hk-dropdown-list').on("click.bs.dropdown", function (event) {

    var $label = $( event.target ).parents('.personalizer-option');

    if(event.target.nodeName == 'LABEL') {

      toggleCheckedAttr($( event.target ));

      var getVals = getCheckedBoxesAsArray($('input[type="checkbox"]:checked')),
          formattedVals = getVals.map(capitalize);

      if (getVals[0] != null) {
        $label.find('button').children('.dropdown-label').text( formattedVals.join(", ") );
      } else {
        $label.find('button').children('.dropdown-label').text( personalizerState.questions[1] );
      }

      if(getVals != '') {
        validate($(event.currentTarget).parents('.personalizer-option'));
      }


      // Prevent dropdown closing.
      event.stopPropagation();

    } else if (event.target.nodeName == 'DIV') {
      // Prevent dropdown closing when clicking inner div of dropdown with checkboxes.

      event.stopPropagation();

    }

  });

  // Function validate:
  //
  // @param event => click event object.
  // @purpose: Will update the icon related to the personalizer option to appear as valid.

  var validate = function(event, unvalidate) {
    var $targetEle, $iconToValidate;
    var unvalidateFlag = unvalidate || false;

    if (event.type == 'select2:select') {
      $targetEle = $(event.target).parents('.personalizer-option');
    } else {
      $targetEle = event.hasOwnProperty('delegateTarget') ? $(event.delegateTarget) : event;
    }

    $iconToValidate = $targetEle.siblings('.personalizer-icon').children('.fa-personalizer');

    if(unvalidateFlag == false) {
      $iconToValidate.addClass('is-valid').data('validated', 'true');
    } else if (unvalidateFlag == true) {
      $iconToValidate.removeClass('is-valid').data('validated', 'false');
    }

    checkIfValid($('.fa-personalizer'));
  };

  // Function checkIfValid:
  //
  // @param event => icon of personalizer option.
  // @purpose: Validates wheter each personalizer step has been answered.
  //
  // @loop: $this.each => Loops through each option to check if they are ALL valid.
  // Return false breaks the loop - meaning the section is not valid yet.
  // Increment count so that we can check after the loop if the count is equal to the number of fields to validate.
  // If the count is equal to the number of fields we need to validate against remove disabled state from button.

  var checkIfValid = function (validEle) {

    var $this = validEle,
        fieldsToValidate = $this.length,
        count = 0;

    $this.each(function() {
      if ($(this).data('validated') != 'true') {
        return false;
      } else {
        count++;
      }
    });

    if(fieldsToValidate == count) {
      $('.btn.isDisabled').removeAttr('disabled').removeClass('isDisabled');
      personalizerState.isValid = !personalizerState.isValid;
    }

  };

  var setCheckboxChoices = function(form, reset) {
    var checkboxChoices = [],
        $form = form,
        $checkedCheckboxes = $form.find('input[type="checkbox"]:checked');

    if (reset) {
      $checkedCheckboxes.prop('checked', false);
      checkboxChoices = [];

      return false;
    } else {
      $checkedCheckboxes.each(function() {
        var val = $( this ).val().indexOf('dependents') > -1 ?
                  $( this ).val().replace(/dependents=/, '') :
                  $( this ).val();

        checkboxChoices.push(val);
      });

      var formattedCheckboxChoices = checkboxChoices.join(',');

      return formattedCheckboxChoices;
    }
  };

  var setDropdownChoice = function(ele, choice) {
    var choice;
    ele.each(function(){
      var value = $(this).find('a').data('personalize');
      if ($(this).find('a').text() == choice) {
        choice = value;
        return false;
      }
    });

    return choice;
  };

  $('form[name="personalizer"]').on('submit', function(event) {
    event.preventDefault();
    var $btn = $( this ).find("button[type='submit']"),
        formData = {},
        postDialect;

    $btn.button('loading');

    formData.age = setDropdownChoice(
      $("[data-option='age'] .hk-dropdown-list-item"),
      $("[data-option='age']").find('.dropdown-label').text()
    );
    formData.dependents = 'dependents='+setCheckboxChoices($('form[name="personalizer"]'));
    formData.risks = setDropdownChoice(
      $("[data-option='concern'] .hk-dropdown-list-item"),
      $("[data-option='concern']").find('.dropdown-label').text()
    );

    formData.lang = personalizerState.dialect;

    if (document.documentElement.lang == 'en') {
      postDialect = 'hk_en';

      dataLayer.push({
        'event':'VirtualGetResult',
        'virtualPageURL':'EN|Get Result|Thankyou',
        'virtualPageTitle': 'EN|Get Result|Thankyou',
        'ProductSuggestAge': formData.age,
        'ProductSuggestCover': formData.dependents,
        'ProductSuggestConcern': formData.risks
      });
    } else {
      postDialect = 'hk_zh-hant';

      dataLayer.push({
        'event':'VirtualGetResult',
        'virtualPageURL':'ZN|Get Result|Thankyou',
        'virtualPageTitle' : 'ZN|Get Result|Thankyou',
        'ProductSuggestAge': formData.age,
        'ProductSuggestCover': formData.dependents,
        'ProductSuggestConcern': formData.risks
      });
    }

    console.log(formData);

    var host = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');

    var postPersonalizerChoices = $.ajax({
        type: 'POST',
        url: host + '/' + postDialect + '/' + 'personalizer.page',
        data : formData
      })
        .done(function(data, textStatus) {
          console.log(data);
          var result = $('<div />').append(data).find('.recommended-card').html();

          $('form[name="personalizer"]').after(function() {
            $('.recommended-card').html("");
            return "<div class='recommended-card bg-gray-cool-3'>" + result + "</div>";
          });
        })
        .fail(function(xhr, textStatus) {
          console.log(xhr);
        });

      postPersonalizerChoices.always(function(data, textStatus) {
        $('.recommended-card').addClass('isShowing');
        $btn.button('reset');
        $('.card .card-header').matchHeight();
        $('.card .card-block').matchHeight();
        $('.card .card-footer').matchHeight();

      });
  });
}
