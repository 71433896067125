import $ from 'jquery';

export default function init () {
  function reposition() {
    var modal = $(this),
        dialog = modal.find('.modal-dialog');

    modal.css('display', 'block');

    // Dividing by two centers the modal exactly, but dividing by three
    // or four works better for larger screens.
    dialog.css("margin-top", Math.max(0, ($(window).height() - dialog.height()) / 2));
  }
  // Reposition when a modal is shown
  $('.modal').on('show.bs.modal', reposition);
  // Reposition when the window is resized
  $(window).on('resize', function() {
    $('.modal:visible').each(reposition);
  });

  $('.videoModal').on('show.bs.modal', function(e){
    var vid = $(e.delegateTarget).find('iframe');
    var src = vid.attr('data-src');
    vid.attr('src', src);
  });
  $('.videoModal').on('hide.bs.modal', function(e) {
    var vid = $(e.delegateTarget).find('iframe');
    if ( vid.length > 0 ){
      // get iframe's src attribute and cache it to a variable
      var src = vid.attr('src');
      // empty the iframe's src attribute
      // (this will kill the video playing)
      vid.attr('src', '');
      // and restore the iframe src url, ready to be played
      // again when the lightbox is displayed
      //vid.attr('src', src);
    }
  });

  $('input:radio[name="greenBandContactMeRadios"]').change(function(){
    $($(this).val()).modal('show');
  });
}
