import $ from 'jquery';

export default function init () {
  var tabCount = $('.dropdown-tabs > li').length;
  if (tabCount) {
    var widthClass = {
      1: 'full',
      2: 'half',
      3: 'third',
      4: 'quarter'
    }[tabCount] || 'quarter';
    $('.dropdown-tabs > li').addClass(widthClass);
  }
  if(tabCount <= 3){
    $(".dropdown-tabs-wrapper .right.carousel-control").hide();
  }

  $('.dropdown-tabs-wrapper .carousel-control.right').on('click', function (e) {
    //$('.dropdown-tabs li').removeClass('active');
    //$('.dropdown-tabs li:nth-child(2)').addClass('active');
    //$('.dropdown-tabs li:first-child').hide();
    //$('.dropdown-tabs li:last-child').show();
    $(".dropdown-tabs li").toggle();
    $(this).hide();
    $('.dropdown-tabs-wrapper .carousel-control.left').show();
  });
  $('.dropdown-tabs-wrapper .carousel-control.left').on('click', function (e) {
    //$('.dropdown-tabs li').removeClass('active');
    //$('.dropdown-tabs li:nth-child(1)').addClass('active');
    //$('.dropdown-tabs li:first-child').show();
    //$('.dropdown-tabs li:last-child').hide();
    $(".dropdown-tabs li").toggle();
    $(this).hide();
    $('.dropdown-tabs-wrapper .carousel-control.right').show();
  });
  $('.dropdown-tabs a').click(function(){
    $('.dropdown-tabs a .fa').removeClass('fa-angle-up').addClass('fa-angle-down');
    $(this).children('.fa').toggleClass('fa-angle-down fa-angle-up');
  });
};
