import $ from 'jquery';

export default function init () {
  $('.masonry .box').on('mouseenter', function () {
    if ($(this).find('a').length) {
      $(this).find('.icon-play-circle').hide();
      $(this).find(".overlay-block").show();
    }
  });
  $('.masonry .box').on('mouseleave', function () {
    $(this).find(".overlay-block").hide();
    $(this).find('.icon-play-circle').show();
  });
};
