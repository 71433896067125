import {pageData, cutUrl} from '../../_resources/modules/compare-plans/compare-plans.js';

if (navigator.userAgent.match(/MSIE 9/) !== null) {
  if (!String.prototype.includes) {
    String.prototype.includes = function () {
      'use strict';
      return String.prototype.indexOf.apply(this, arguments) !== -1;
    };
  }
}

export function Product(name, data) {
  this.name = name;
  this.data = data || {};
}

Product.prototype = {
  setData: function(newData) {
    this.data = newData;
  }
};

/*function getStoredProduct(){
  if ($("#planCompare").exists()) {
    var section = location.pathname.split("/").slice(-2, -1).toString();
    var subSection = location.pathname.split("/").slice(-1).toString();
    if(subSection != ""){
      var storedProducts;
      subSection.includes('.html') ?
      storedProducts = JSON.parse(sessionStorage.getItem(subSection.replace(/.html/g, ''))) :
      storedProducts = JSON.parse(sessionStorage.getItem(subSection));
    }else{
      var storedProducts = JSON.parse(sessionStorage.getItem(section));
    }
    console.log(storedProducts);
    if(storedProducts){
      return storedProducts;
    } else {
      return [];
    }
  } else {
    return false;
  }
}*/

/*export function removeStoredProduct() {
  var section = location.pathname.split("/").slice(-2, -1).toString();
  sessionStorage.removeItem(section);
}*/

export var products = [];

export function clearProducts() {
  // Not on a product page, filter product array keeping only the current product.
  if (pageData.product.one) {
    products = [products[0]];
  } else {
    products = [];
  }
  return products;
}

export function clearVirtualProducts(products) {
  // Not on a product page, filter product array keeping only the current product.
  if (pageData.product.one) { // product page
    products = [products[0]];
  } else {
    products = [];
  }
  return products;
}
