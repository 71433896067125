export default function init () {
    var INTERSTITIAL_WAIT = window.interstitialTime || 5000; // will be configured in TS and exposed

    var interstitialsShown = parseInt(sessionStorage.getItem('interstitialsShown') || 0);
    var interstitialTimer;
    var interstitialShownOnPage = false;

    function showInterstitial() {
        // dont show if alrady shown on page or shown twice during session or if a modal is open
        if (interstitialsShown < 2 && $('#interstitial').exists() && !interstitialShownOnPage && !$('.modal.in').exists() && $('#campaigncode').exists()) {
            $('#interstitial').modal('show');
            clearTimeout(interstitialTimer);
            interstitialsShown += 1;
            sessionStorage.setItem('interstitialsShown', interstitialsShown);
            interstitialShownOnPage = true;
        }
    }

    function resetInterstitialTimer() {
      clearTimeout(interstitialTimer);
      interstitialTimer = setTimeout(showInterstitial, INTERSTITIAL_WAIT);
    }

    if (interstitialsShown < 2 && $('#interstitial').exists() && $('#campaigncode').exists()) {
      resetInterstitialTimer();
      $(document).on('scroll click keypress mousemove', resetInterstitialTimer);
    }
};
