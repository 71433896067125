export function addMap() {
  var gmapDiv = document.getElementById("gmap");
  var mapInstance = document.createElement("iframe");

  mapInstance.src = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3692.0089373873725!2d114.16639001472258!3d22.277651285335406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040051512888d3%3A0x76d1698071a6b41e!2sCIGNA+Worldwide+Life+Insurance+Company+Ltd!5e0!3m2!1sen!2sus!4v1476465035784"; 
  mapInstance.classList = "embed-responsive-item embed-google-maps-item";
  mapInstance.width = "100%";
  mapInstance.height = "100%";
  mapInstance.frameBorder = "0";
  mapInstance.style = "border:0;";
  mapInstance.allowFullscreen = "true";

  return gmapDiv.appendChild(mapInstance);
}