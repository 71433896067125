import
{
    quotes, quotesMobile
}
from '../../../_static/js/quotes.js';

export default function () {


    var actionBaseURL = location.protocol + '//' + location.host;
    var applyBaseURL = window.location.host;
    if(applyBaseURL == "uat.cigna.com.hk" || applyBaseURL == "uatts.cigna.com.hk") {
      applyBaseURL = "uatts.cigna.com.hk";
    }
    else {
      applyBaseURL = "www.cigna.com.hk";
    }
    //applyBaseURL = (window.location.host == "uatts.cigna.com.hk") ? "uat.cigna.com.hk" : "www.cigna.com.hk";
    applyBaseURL = location.protocol + '//' + applyBaseURL;
    var quoteId = "",
        campaignCode = "",
        contactMeClick = false;
    var locale;

    if (document.documentElement.lang == 'en') {
      locale = 'hk_en';
    } else {
      locale = 'hk_zh-hant';
    }

    var actions = {
      contactme: actionBaseURL + '/eSalesHKWS/ContactMe.do?',
      contactus: actionBaseURL + '/eSalesHKWS/Contactus.do?',
      productquote: actionBaseURL + '/eSalesHKWS/GetQuote.do?',
      emailquote: actionBaseURL + '/eSalesHKWS/SendQuote.do?',
      quoteinfo: actionBaseURL + '/eSalesHKWS/getquoteinfo.do?',
      // emailcollection: actionBaseURL + '/eSalesHKWS/Emailcollection.do?',
      // emailsubscription: actionBaseURL + '/eSalesHKWS/Emailsubscription.do?',
      // emailunsubscribe: actionBaseURL + '/eSalesHKWS/Emailunsubscription.do?',
      userSessionPage: location.protocol + '//' + location.host + '/' + locale + '/user-session.page'
    };
    var language = ($('html').attr("lang") == "en") ? "en_US":"zh_HK";
    var virtualLanguage = (language == "en_US") ? "EN" : "ZN";
    var formatMoney = function (value) {
      // Convert the value to a floating point number in case it arrives as a string.
      var numeric = parseFloat(value);
      // Specify the local currency.
      return numeric.toLocaleString('HKD', { style: 'currency', currency: "HKD", minimumFractionDigits: 0, maximumFractionDigits: 2 });
    };
    var storedVars = {
      setVar: function(index, Element) {
        var $this = Element;
        switch (index) {
          case 0:
            storedVars.firstname = $this.text();
            break;
          case 1:
            storedVars.lastname = $this.text();
            break;
          case 2:
            storedVars.phone = $this.text();
            break;
          default:
            break;
        }
      },
      setStoredVarsInForms: function() {
        var $forms = $('form');

        $forms.each(function() {
          var $this = $(this);

          if( $this.find('input[name="firstname"]').exists() && storedVars.firstname) {
            $this.find('input[name="firstname"]').val(storedVars.firstname);
          }

          if( $this.find('input[name="lastname"]').exists() && storedVars.lastname ) {
            $this.find('input[name="lastname"]').val(storedVars.lastname);
          }

          if( $this.find('input[name="phone"]').exists() && storedVars.phone ) {
            $this.find('input[name="phone"]').val(storedVars.phone);
          }

        });
      }
    };

    $.fn.serializeObject = function () {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    function virtualTracking(event, tagName) {
      var virtualLanguage = (language == "en_US") ? "EN" : "ZN";

      dataLayer.push({
        'event': event,
        'virtualPageURL': virtualLanguage + tagName,
        'virtualPageTitle' : virtualLanguage + tagName
      });
    }

    function handleQuoteResp(formEle, respEle) {
      formEle.toggleClass('hidden');
      respEle.toggleClass('hidden');
    }

    function handleErr(form, err) {
      $(form+'Req').toggleClass('hidden');
      $(form+'Err').toggleClass('hidden');
    }

    function handleErrWithMessage(form, err) {
      $(form+'Err .error-alert-message').text(err);
      if (!$(form+'Req').hasClass('hidden') && $(form+'Req').exists() ) {
        $(form+'Req').toggleClass('hidden');
        $(form+'Err').toggleClass('hidden');
      }
    }

    function handleFormResp(formEle, respEle) {
        formEle.toggleClass('hidden');
        respEle.toggleClass('hidden')
    }

    function populateFields(frmObj, formData) {
        //console.log(frmObj);
      var keys = [{
          element: "productname",
          name: "name",
          data: (formData) ? formData.name : ""
        },
        {
          element: "gender",
          name: "gender",
          data: (formData) ? formData.gender : ""
        },
        {
          element: "dob",
          name: "quotedob",
          data: frmObj.quotedob
        },
        {
          element: "email",
          name: "email",
          data: (formData) ? formData.email : ""
        },
        {
          element: "smoker",
          name: "smoking",
          data: (formData) ? formData.smoking : ""
        },
        {
          element: "offercode",
          name: "offercode",
          data: (formData) ? formData.offercode : ""
        },
        {
          element: "coverage",
          name: "si",
          data: (formData) ? formData.si : ""
        },
        {
          element: "mon-premium",
          name: "BPMonthlyPrem",
          data: formatMoney(frmObj.BPMonthlyPrem)
        },
        {
          element: "ann-premium",
          name: "BPAnnualPrem",
          data: formatMoney(frmObj.BPAnnualPrem)
        },
        {
          element: "quoteId",
          name: "Quoteid",
          data: frmObj.Quoteid
        }];

      $.each(keys, function(index, key){
        if(typeof(key.data)  != "undefined" && key.data != "") {
          if(key.name == "gender") {
            if(language == "en_US"){
              key.data = (key.data == "M") ? "Male" : "Female";
            }
            else {
              key.data = (key.data == "M") ? "男性" : "女性";
            }
          }
          else if(key.name == "smoking") {
            if(language == "en_US") {
              key.data = (key.data == "0") ? "No" : "Yes";
            }
            else {
              key.data = (key.data == "0") ? "沒有" : "有";
            }
          }

          $('.form-quote-response .' + key.element).removeClass("hidden");
          $('.form-quote-response .' + key.element + " .form-control-static").text(key.data);
        }
        else {
          if(key.name == "offercode" && $('.form-quote-response .' + key.element).hasClass("hidden") == false) {
            $('.form-quote-response .' + key.element).addClass("hidden");
            $('.form-quote-response .' + key.element + " .form-control-static").text("");
          }
        }
      });

      /*
        var $response = $('.form-quote-response');
        var $genderField = $response.find('.gender');
        var $birthdateField = $response.find('.dob');
        var $smokerField = $response.find('.smoker');
        var $coverageField = $response.find('.coverage');
        var $quoteIdField = $response.find('.quoteId');
        var $monthlyPremium = $response.find('.mon-premium');
        var $annualPremium = $response.find('.ann-premium');

        $birthdateField.find(".form-control-static").text(frmObj['quotedob']);
        $quoteIdField.find(".form-control-static").text(frmObj['Quoteid']);
        $monthlyPremium.text(formatMoney(frmObj["BPMonthlyPrem"]));
        $annualPremium.text(formatMoney(frmObj["BPAnnualPrem"]));
      */
    }

    function populateContactMeFields(data, formId) {
        var $form = $(formId);
        var $campaigncode = $form.find('input[name="campaigncode"]');
        $campaigncode.val(data.quotecampaign);
    }

    function requote() {
      if($('#retrieveQuoteReq').exists()) {handleQuoteResp($('#retrieveQuoteReq'), $('#retrieveQuoteResp'));}
      else{handleQuoteResp($('#quoteReq'), $('#quoteResp'));}
    }

    function goBackToForm(event) {
        var $eventTargetForm = $(event.target).data('back');
        var $goBackParent = $(event.target).parents('.container');
        var formId = $goBackParent.attr('id');
        var $lightboxCheck = $goBackParent.parents('.lightbox-content');

        if ($lightboxCheck.exists()) {
            $lightboxCheck.toggleClass('danger');
        }

        return handleErr('#' + $eventTargetForm);
    }

    function goBackState() {
      var state = $(this).attr("data-go-back");
      $("#" + state + 'Err').toggleClass('hidden');
      $("#" + state + 'Req').toggleClass('hidden');
    }

    function submitContactMe(form, action) {
      $(form).parsley().on('field:validated',function () {
        $('.parsley-error').next().removeClass('hidden');
        $('.parsley-success').next().addClass('hidden');
      }).on('form:submit', function (e) {

        $(form + ' button[type="submit"]').prop("disabled", true);
        $(form + ' button[type="submit"]').prepend('<i class="fa fa-spinner fa-lg fa-spin form-submit" style="color: #ffffff;"></i> ');

          var uri = decodeURIComponent($(form).serialize());

        var ele = $(this.$element);

        var frmSubmit = $.get(action, uri)
          .done(function(response) {
            //console.log(response);
            if(response.status == 'success') {
              //handleQuoteResp($(form+'Req'), $(form+'Resp'));
              submitSessions();
              switch (ele.attr('id')) {
                case "contactMe":
                    handleFormResp($(form+'Req'), $('#contactMeResp'));
                  break;

                case "contactMe2":
                  handleFormResp($(form).closest("#contactMeReq"), $(form).closest("#contactMeReq").next("#contactMeResp"));
                  break;

                case "contactMePromo":
                  handleFormResp($(form+'Req'), $(form+'Resp'));
                  break;

                case "contactMe2Promo":
                  handleFormResp($(form+'Req'), $(form+'Resp'));
                  break;

                case "contactMeQuote":
                  $(".go-back-state").show();
                  handleFormResp($(form+'Req'), $(form+'Resp'));
                  break;

                case "productContact":
                    handleFormResp(ele, $('#productContactResp'));
                  break;

                case "navContactMe":
                    handleFormResp($(form+'Req'), $(form+'Resp'));
                  break;

                case "contactMeMobileNav":
                    handleFormResp($(form + '-formReq'), $(form + '-formResp'));
                  break;

                default:
                    handleFormResp(ele, ele.next());
                  break;
              }
            }
            else {
                if (ele.attr('id') == "productContact") {
                    var $modalTypeCheck = ele.parents('.lightbox-content');
                    if ($modalTypeCheck.exists())
                        $modalTypeCheck.toggleClass('danger');
                    handleErr(form, response.error);
                }
                else if(ele.attr('id') == "contactMe2") {
                  $(form).closest("#contactMeReq").toggleClass('hidden');
                  $(form).closest("#contactMeReq").next("#contactMeResp").next("#contactMeErr").toggleClass('hidden');
                }
                else {
                    handleErr(form, response.error);
                }
            }
          })
          .fail(function() { /* call error modal */
            handleErr(form, null);
          });

        frmSubmit.always(function() {
          $(form + ' .form-submit').remove();
          $(form + ' button[type="submit"]').prop("disabled", false);

          switch (ele.attr('id')) {
            case "contactMe":
              //virtualTracking("VirtualProductContactUSGIT", "|Get In Touch Contact|Thankyou");
              if(contactMeClick){
                dataLayer.push({
                  'event': "VirtualProductContactUSGIT",
                  'virtualPageURL': virtualLanguage + "|Get In Touch Contact|Thankyou",
                  'virtualPageTitle' : virtualLanguage + "|Get In Touch Contact|Thankyou",
                  'Productcampaigncode' : campaignCode
                });
              }
              else {
                dataLayer.push({
                  'event': "VirtualPromotionalPopUp",
                  'virtualPageURL': virtualLanguage + "|PromotionalPopUp|Thankyou",
                  'virtualPageTitle' : virtualLanguage + "|PromotionalPopUp|Thankyou",
                  'Productcampaigncode' : campaignCode
                });
              }
              break;

            case "contactMe2":
              //virtualTracking("VirtualProductContactUSGIT", "|Get In Touch Contact|Thankyou");
              if(contactMeClick){
                dataLayer.push({
                  'event': "VirtualProductContactUSGIT",
                  'virtualPageURL': virtualLanguage + "|Get In Touch Contact|Thankyou",
                  'virtualPageTitle' : virtualLanguage + "|Get In Touch Contact|Thankyou",
                  'Productcampaigncode' : campaignCode
                });
              }
              else {
                dataLayer.push({
                  'event': "VirtualPromotionalPopUp",
                  'virtualPageURL': virtualLanguage + "|PromotionalPopUp|Thankyou",
                  'virtualPageTitle' : virtualLanguage + "|PromotionalPopUp|Thankyou",
                  'Productcampaigncode' : campaignCode
                });
              }
              break;

            case "contactMePromo":
              //virtualTracking("VirtualProductContact", "|Products|ContactME Form|Thankyou");
              dataLayer.push({
                'event': "VirtualProductContact",
                'virtualPageURL': virtualLanguage + "|Products|ContactME Form|Thankyou",
                'virtualPageTitle' : virtualLanguage + "|Products|ContactME Form|Thankyou",
                'Productcampaigncode' : campaignCode
              });
              break;

            case "contactMe2Promo":
              //virtualTracking("VirtualProductContact", "|Products|ContactME Form|Thankyou");
              dataLayer.push({
                'event': "VirtualProductContact",
                'virtualPageURL': virtualLanguage + "|Products|ContactME Form|Thankyou",
                'virtualPageTitle' : virtualLanguage + "|Products|ContactME Form|Thankyou",
                'Productcampaigncode' : campaignCode
              });
              break;

            case "contactMeQuote":
              //virtualTracking("VirtualContactmeSecond", "|Get a QuoteContactSecond|Thankyou");
              var formData = $(form).serializeObject();
              dataLayer.push({
                'event': "VirtualContactmeSecond",
                'virtualPageURL': virtualLanguage + "|Get a QuoteContactSecond|Thankyou",
                'virtualPageTitle' : virtualLanguage + "|Get a QuoteContactSecond|Thankyou",
                'Productcampaigncode' : campaignCode,
                'PersonalizedQuoteSecond' : formData.quoteid
              });
              break;

            case "productContact":
              handleFormResp(ele, $('#productContactResp'));
              break;

            case "navContactMe":
              //virtualTracking("VirtualHeaderContact", "|Header|ContactUS|Thankyou");
              dataLayer.push({
                'event': "VirtualHeaderContact",
                'virtualPageURL': virtualLanguage + "|Header|ContactUS|Thankyou",
                'virtualPageTitle' : virtualLanguage + "|Header|ContactUS|Thankyou",
                'Productcampaigncode' : $("#campaigncode").val()
              });
              break;

            case "contactMeMobileNav":
              //virtualTracking("VirtualHeaderContact", "|Header|ContactUS|Thankyou");
              dataLayer.push({
                'event': "VirtualHeaderContact",
                'virtualPageURL': virtualLanguage + "|Header|ContactUS|Thankyou",
                'virtualPageTitle' : virtualLanguage + "|Header|ContactUS|Thankyou"
              });
              break;

            default:
              break;
          }
        });

        return false;
      });


    }

    function submitContactUs(form, action) {
        $(form).parsley().on('field:validated',function () {
            $(form + ' .parsley-error').next().removeClass('hidden');
            $(form + ' .parsley-success').next().addClass('hidden');
        }).on('form:submit', function (e) {
          $(form + ' button[type="submit"]').prop("disabled", true);
          $(form + ' button[type="submit"]').prepend('<i class="fa fa-spinner fa-lg fa-spin form-submit" style="color: #ffffff;"></i> ');

            var uri = decodeURIComponent($(form).serialize());

            var ele = $(this.$element);

            var frmSubmit = $.get(action, uri)
              .done(function(response) {
                if(response.status == 'success') {

                  if(ele.attr('id') == "customer-service-form") {
                    handleFormResp(ele, $("#customer-service-formResp"));
                  } else {
                    handleQuoteResp(ele, ele.next());
                  }

                  submitSessions(ele);
                } else {
                  $('#genericError').modal('toggle');
                }

               })
               .fail(function(){
                if(ele.attr('id') == "customer-service-form")
                  handleErr("#customerService", null);
                else
                  handleErr(form, null);
              });

            frmSubmit.always(function(){
              $(form + ' .form-submit').remove();
              $(form + ' button[type="submit"]').prop("disabled", false);
            });

            return false;
        });
    }

    function submitForm(form, action) {
        //console.log(form);
        $(form).parsley().on('field:validated',function () {
            $('.parsley-error').next().removeClass('hidden');
            $('.parsley-success').next().addClass('hidden');
        }).on('form:submit', function (e) {
            var uri = decodeURIComponent($(form).serialize());

            var ele = $(this.$element);


           var frmSubmit = $.get(action, uri)
               .done(function(response) {
                   //console.log(response);
                   populateFields(response);
                   submitSessions();
                })
               .fail(function() {
                   alert('Failed'); /* call error modal */
                   //console.log("Error");
                })

            frmSubmit.always(function() {
                //console.log('always');

                handleQuoteResp(ele, $('#quoteResp'));
            });

            return false;
        });
    }

    function submitQuoteForm(form, action) {
        $(form).parsley().on('field:validated',function () {
            $('.parsley-error').next().removeClass('hidden');
            $('.parsley-success').next().addClass('hidden');
        }).on('form:submit', function (e) {

          $(form + ' button[type="submit"]').prop("disabled", true);
          $(form + ' button[type="submit"]').prepend('<i class="fa fa-spinner fa-lg fa-spin form-submit" style="color: #ffffff;"></i> ');

          var uri = decodeURIComponent($(form).serialize());

            var ele = $(this.$element);

           var frmSubmit = $.get(action, uri)
               .done(function(response) {
                   if(response.status == 'success') {
                     quoteId = response.Quoteid;

                     var formData = $(form).serializeObject();
                     var parameters = $(form).serializeObject();
                     if(parameters.hasOwnProperty("lang")){ delete parameters["lang"]; }
                     if(parameters.hasOwnProperty("action")){ delete parameters["action"]; }
                     if(parameters.hasOwnProperty("dob-dates-min")){ delete parameters["dob-dates-min"]; }
                     if(parameters.hasOwnProperty("dob-dates-max")){ delete parameters["dob-dates-max"]; }

                     var applyNow = $("#quote-apply-now").attr("href") + "&quoteid=" + response.Quoteid + "&" + $.param(parameters);
                     $("#quote-apply-now").attr("href", applyNow);

                     if(formData.hasOwnProperty("email")){
                       if(formData.email != "") {
                         //var emailQuote = $("#quote-email").attr("href") + "action=emailquote&" + uri + "&quoteid=" + response.Quoteid;
                         var emailQuoteUrl = actions.emailquote + "action=emailquote&" + uri + "&quoteid=" + response.Quoteid;
                         //$("#quote-email").attr("href", emailQuote);
                         $("#quote-email").removeClass("hidden");

                         $("#quote-email").click(function(){
                           $("#quote-email").prop("disabled", true);
                           $("#quote-email").prepend('<i class="fa fa-spinner fa-lg fa-spin email-submit" style="color: #ffffff;"></i> ');
                           $.ajax({
                             type: "POST",
                             dataType: 'json',
                             url: emailQuoteUrl
                           })
                             .done(function(response){
                               $("#quote-email .email-submit").removeClass("fa-spinner").removeClass("fa-spin").addClass("fa-check");
                               $("#quote-email").prop("disabled", false);

                               if (response.status == "success") {}
                               else {}
                             })
                             .fail(function () {
                               console.log("Sorry about this...Please try it again later.");
                             })
                         });

                         if($("#quote-email-error").hasClass("hidden") == false){
                           $("#quote-email-error").addClass("hidden");
                         }
                       }
                       else {
                         $("#quote-email-error").removeClass("hidden");
                         if($("#quote-email").hasClass("hidden") == false){
                           $("#quote-email").addClass("hidden");
                         }
                       }
                     }
                     else {
                       $("#quote-email-error").removeClass("hidden");
                       if($("#quote-email").hasClass("hidden") == false){
                         $("#quote-email").addClass("hidden");
                       }
                     }

                     response.quotedob = $(form).find("input[name='dob']").val();

                     if($(form).find("select[name='offercode']").length > 0) {
                       formData.offercode = $(form).find("select[name='offercode'] option:selected").text();
                     }
                     formData.si = $(form).find("select[name='si'] option:selected").text();
                     populateFields(response, formData);
                     handleFormResp($('#quoteReq'), $('#quoteResp'));
                     submitSessions();
                   }
                   else {
                     handleErrWithMessage('#quote', response.message);
                   }
                })
               .fail(function(err) {
                  handleErr('#quote', err);
                });

            frmSubmit.always(function() {
              $(form + ' .form-submit').remove();
              $(form + ' button[type="submit"]').prop("disabled", false);

              //virtualTracking("VirtualGetQuoteFirst", "|GetQuotefirst|QuoteResult");
              dataLayer.push({
                'event': "VirtualGetQuoteFirst",
                'virtualPageURL': virtualLanguage + "|GetQuotefirst|QuoteResult",
                'virtualPageTitle' : virtualLanguage + "|GetQuotefirst|QuoteResult",
                'QuoteIDFirst' : quoteId
              });
            });

            return false;
        });

    }

    function submitRetrieveQuoteForm(form, action) {
        $(form).parsley().on('field:validated',function () {
            $('.parsley-error').next().removeClass('hidden');
            $('.parsley-success').next().addClass('hidden');

        }).on('form:submit', function (e) {
            var uri = decodeURIComponent($(form).serialize());

            var ele = $(this.$element);

           var frmSubmit = $.get(action, uri)
                .done(function(response){
                   if(response.status == "success") {
                     quoteId = response.Quoteid;
                     campaignCode = response.quotecampaign;

                     $(".go-back-state").show();
                     if(form == "#retrieveQuoteRightUtil"){
                       $("#retrieveQuoteRightUtil .retrieveQuoteRightUtilInstruct").show();
                       $("#retrieveQuoteRightUtil .retrieveQuoteRightUtilErrorMsg").hide();
                       //$("#retrieveQuoteRightUtil .retrieveQuoteRightUtilErrorMsg").text("");
                     }
                     else {
                       $("#retrieveQuoteMobile .retrieveQuoteMobileUtilInstruct").show();
                       $("#retrieveQuoteMobile .retrieveQuoteMobileUtilErrorMsg").text("");
                     }
                     var options = {
                       campaignCode: campaignCode,
                       offerCode: response.hasOwnProperty("quoteoffercode") ? response.quoteoffercode : ""
                     };
                     var startquoteForm = new quoteForm('#quote-form', options);
                     startquoteForm.init(function(Product) {
                       /* Process if the product is available */
                       if (Product.isAvailable) {
                         $('.modal #reQuoteLink').attr("href", Product.data.productLink);

                         var siLabel = "";
                         if(Product.data.offerCodes.length > 0) {
                           $.each(Product.data.offerCodes, function (key, offerCode) {
                             if(offerCode.sumInsured.value == response.quotesi) {
                               $('.form-quote-response .coverage .control-label').html('<span class="text-green-logo">*</span>' + offerCode.sumInsured.fieldLabel);
                               siLabel = offerCode.sumInsured.label;
                             }
                           });
                         }

                         if(Product.data.offerFieldLabel != ""){
                           $('.offercode .control-label').text(Product.data.offerFieldLabel);
                           startquoteForm.getOfferCodeLabel(function(label){
                             populateFields(response, {name: Product.data.name, offercode: label, si: siLabel});
                             $("#retrieve-a-quote-modal").modal('toggle');
                           });
                         }
                         else {
                           populateFields(response, {name: Product.data.name, si: siLabel});
                           $("#retrieve-a-quote-modal").modal('toggle');
                         }


                         if(Product.data.buyNowURL != "") {
                           var parameters = $(form).serializeObject();
                           if(parameters.hasOwnProperty("lang")){ delete parameters["lang"]; }
                           if(parameters.hasOwnProperty("action")){ delete parameters["action"]; }
                           if(parameters.hasOwnProperty("dob-dates-min")){ delete parameters["dob-dates-min"]; }
                           if(parameters.hasOwnProperty("dob-dates-max")){ delete parameters["dob-dates-max"]; }

                           var applyNow = $("#quote-apply-now").attr("href") + "&quoteid=" + response.Quoteid + "&" + $.param(parameters);
                           $("#quote-apply-now").attr("href", applyNow);
                           $("#quote-apply-now").show();
                         }
                         else {
                           $("#quote-apply-now").hide();
                         }
                       }
                     });

                     if ($("#contactMeQuote").exists()) {
                       populateContactMeFields(response, "#contactMeQuote");
                     }
                     submitSessions();

                     dataLayer.push({
                       'event': "VirtualReterieveQuote",
                       'virtualPageURL': virtualLanguage + "|VirtualRetrieveQuote|Thankyou",
                       'virtualPageTitle' : virtualLanguage + "|VirtualRetrieveQuote|Thankyou",
                       'Productcampaigncode' : campaignCode
                     });

                     //$("#retrieve-a-quote-modal").modal('toggle');
                   }
                  else {
                     if(form == "#retrieveQuoteRightUtil"){
                       $("#retrieveQuoteRightUtil .retrieveQuoteRightUtilInstruct").hide();
                       //$("#retrieveQuoteRightUtil .retrieveQuoteRightUtilErrorMsg").text("Sorry, your input does not match our records. Please check your entry and try again. " + response.message).show();
                       $("#retrieveQuoteRightUtil .retrieveQuoteRightUtilErrorMsg").show();
                     }
                     else {
                       $("#retrieveQuoteMobile .retrieveQuoteMobileUtilInstruct").hide();
                       //$("#retrieveQuoteMobileUtil .retrieveQuoteMobileUtilErrorMsg").text("Sorry, your input does not match our records. Please check your entry and try again. " + response.message).show();
                      // $("#retrieveQuoteMobile .retrieveQuoteMobileUtilErrorMsg").text("Sorry, your input does not match our records. Please check your entry and try again.").show();
                      $("#retrieveQuoteMobile .retrieveQuoteMobileUtilErrorMsg").show();
                     }
                   }
                })
               .fail(function(err) {
                  $(".go-back-state").hide();
                  handleErr('#retrieveQuote', err);
                  $("#retrieve-a-quote-modal").modal('toggle');
                });

            frmSubmit.always(function() {
              if(!$('#quoteModal #quote-form').hasClass('hidden') && $('#quoteModal #quote-form').exists()) {
                    $('#quote-form').addClass('hidden');
                    $('#quoteResp').removeClass('hidden');
                }
            });

            return false;
        });
    }

    /* Use for 'Get A Quote' form only */
    function quoteForm(formId, options) {
      var defaults = {
        lang: ($('html').attr("lang") == "en") ? "en-us":"zh-hant",
        campaignCode: null,
        categoryData: [],
        product: {},
        offerCode: null,
        offerCodes: [],
        sumInsured: {}
      };

      var selectedIndex = 1;

      options = $.extend(defaults, options);

      this.init = function(callback){
        var path = location.href.includes('localhost') ? '/static/json/' : '/iwov-resources/json/';

        $.ajax({
          dataType: 'json',
          url: path + options.lang + "/products.json"
        })
          .done(function(response){
            if (response.category.length > 0) {
              $.each(response.category, function (key, category) {
                if (typeof(category) == "object") {
                  if ($.isArray(category.products)) {
                    $.each(category.products, function (key, product) {
                      if(product.hasOwnProperty("campaignCode")) {
                        if (options.campaignCode == product.campaignCode) {
                          options.product = product;
                          callback({isAvailable: true, data: product, message: "Product available"});
                        }
                      }
                    });
                  }
                  else {
                    callback({isAvailable: false, message: "No products"});
                  }
                }
                else {
                  callback({isAvailable: false, message: "No category"});
                }
              });
            }
            else {
              return {isAvailable: false, message: "No category"};
            }
          })
          .fail(function () {
            console.log("Sorry about this...Please try it again later.");
          })
          .always(function () {});
      };

      this.setWatcher = function(element, callback) {
        $(element).change(function(){
          var name = $(element).prop('name');
          options.sumInsured = {};

          if(name == "offercode") {
            selectedIndex = $(element).prop('selectedIndex');
            options.offerCode = $(element).val();
          }
          if(options.offerCode != "" && name == "si") {
            $.each(options.product.offerCodes, function (key, o) {
              if(options.offerCode == o.value && o.sumInsured.value == $(element).val()) {
                options.sumInsured = o.sumInsured;
                return false;
              }
            });
          }
          /*
          if(selectedIndex-1 > -1 && options.product.offerCodes.length > 0) {
            console.log(selectedIndex);
            if (options.product.offerCodes[selectedIndex - 1].hasOwnProperty("sumInsured")) {
              options.sumInsured = options.product.offerCodes[selectedIndex - 1].sumInsured;
            }
            else {
              options.sumInsured = {};
            }
          }
          */

          callback();
        });
      };
      this.populateSI = function(){
        //$('select[name="si"]').children('option:not(:first)').remove();
        $('select[name="si"]').children('option').remove();
        $('select[name="si"]').append('<option value="" read-only>' + options.product.offerCodes[0].sumInsured.fieldLabel + '</option>');
        $('label[for="si"]').html('<span class="text-green-logo">*</span>' + options.product.offerCodes[0].sumInsured.fieldLabel);
        if(options.product.offerCodes.length > 0) {
          $.each(options.product.offerCodes, function (key, offerCode) {
            if(offerCode.value == options.offerCode) {
              //console.log(offerCode.value + " is match!");
              $('select[name="si"]').append('<option value="' + offerCode.sumInsured.value + '">' + offerCode.sumInsured.label + '</option>');
            }
          });
        }
        else { /* No offers */ }
      };
      this.populateDOBDatesRange = function() {
        var today = new Date(),
          ageYoung = null,
          ageOld = null,
          minYear = null,
          maxYear = null,
          minDate = null,
          maxDate = null,
          yearRange = null;
        if($.isEmptyObject(options.sumInsured) == false && $('select[name="si"]').val() != "") {
          if ($(formId+' input[name="dob-dates-min"]').exists()) {
            if(options.sumInsured.dobMinDateType == "Years") {
              minYear = (today.getFullYear() - parseInt(options.sumInsured.dobMinDate));
              minDate = minYear + "/" + (today.getMonth() + 1) + "/" + today.getDate();
            }
            else if(options.sumInsured.dobMinDateType == "Days") {
              minDate = new Date();
              minDate.setDate(minDate.getDate() - parseInt(options.sumInsured.dobMinDate));
              minYear = minDate.getFullYear();
              minDate = minDate.getFullYear() + "/" + (minDate.getMonth() + 1) + "/" + minDate.getDate();
            }
            ageOld = new Date(minDate);
            $(formId+' #quote-datepicker').datepicker('option', 'minDate', ageOld);
            $(formId+' input[name="dob-dates-min"]').val(minDate);
          }
          if ($(formId+' input[name="dob-dates-max"]').exists()) {
            if(options.sumInsured.dobMaxDateType == "Years") {
              maxYear = (today.getFullYear() - parseInt(options.sumInsured.dobMaxDate));
              maxDate = maxYear + "/" + (today.getMonth() + 1) + "/" + today.getDate();
            }
            else if(options.sumInsured.dobMaxDateType == "Days") {
              maxDate = new Date();
              maxDate.setDate(maxDate.getDate() - parseInt(options.sumInsured.dobMaxDate));
              maxYear = maxDate.getFullYear();
              maxDate = maxDate.getFullYear() + "/" + (maxDate.getMonth() + 1) + "/" + maxDate.getDate();
            }
            ageYoung = new Date(maxDate);
            $(formId+' #quote-datepicker').datepicker('option', 'maxDate', ageYoung);
            $(formId+' input[name="dob-dates-max"]').val(maxDate);
          }
          yearRange = minYear + ":" + maxYear;
          $(formId+' #quote-datepicker').datepicker('option', 'yearRange', yearRange);
          $(formId+' #birthdate').prop('disabled', false);
        }
        else {
          yearRange = "-100:+0";
          $(formId+' #quote-datepicker').datepicker('option', 'yearRange', yearRange);
          $(formId+' input[name="dob-dates-min"]').val("");
          $(formId+' input[name="dob-dates-max"]').val("");
          $(formId+' #birthdate').prop('disabled', true);
          $(formId+' #quote-datepicker').datepicker('setDate', null);
        }
      };
      this.getOfferCodeLabel = function(callback){
        if(options.product.offerCodes.length > 0) {
          $.each(options.product.offerCodes, function (key, offerCode) {
            if(offerCode.value == options.offerCode) {
              callback(offerCode.label);
            }
          });
        }
        else { /* No offers */ }
      };
    }

    if ($("#quote-form").length > 0) {
      if ($("#quote-form").find("#campaigncode").val() != "")
        $("form").not('#navContactMe').find("input[name='campaigncode']").val($("#quote-form").find("#campaigncode").val());
    }

    if ($('#productContact').exists()) {
      submitContactMe('#productContact', actions.contactme);
    }

    if ($('#contactMe').exists()) {
      campaignCode = $("#campaigncode").val();
      submitContactMe('#contactMe', actions.contactme);
    }

    if ($('#contactMe2').exists()) {
      campaignCode = $("#campaigncode").val();
      submitContactMe('#contactMe2', actions.contactme);
    }

    if ($('#contactMePromo').exists()) {
      campaignCode = $("#campaigncode").val();
      submitContactMe('#contactMePromo', actions.contactme);
    }

    if ($('#contactMe2Promo').exists()) {
      campaignCode = $("#campaigncode").val();
      submitContactMe('#contactMe2Promo', actions.contactme);
    }

    if ($('#contactMeQuote').exists()) {
      campaignCode = $("#campaigncode").val();
      submitContactMe('#contactMeQuote', actions.contactme);
    }

    if ($('#contactMeMobileNav').exists()) {
      submitContactMe('#contactMeMobileNav', actions.contactme);
    }

    if ($('#contactUs').exists()) {
      submitContactUs('#contactUs', actions.contactus);
    }

    if ($('#contact-us').exists()) {
      submitContactUs('#contact-us', actions.contactus);
    }

    if ($('#customer-service-form').exists()) {
      submitContactUs('#customer-service-form', actions.contactus);
    }

    if ($('#retrieveQuoteMobile').exists()) {
      submitRetrieveQuoteForm('#retrieveQuoteMobile', actions.quoteinfo);
    }

    if ($('#quote-form').exists()) {
      campaignCode = $("#campaigncode").val();
      submitQuoteForm('#quote-form', actions.productquote);

      var options = {
        campaignCode: campaignCode,
        offerCode: ""
      };

      $('#quote-form #birthdate').prop('disabled', true);

      if ($('#quote-form select[name="offercode"]').exists()) {
        //$('#quote-form .dropdown-menu').hide();
        /* Select offercode */
        options.offerCode = $('#quote-form select[name="offercode"]').val();

        var startquoteForm = new quoteForm('#quote-form', options);
        startquoteForm.init(function(Product){
          /* Process if there are offer codes available */
          if(Product.isAvailable) {
            $(".form-quote-response .productname .form-control-static").text(Product.data.name);
            startquoteForm.populateSI();
            startquoteForm.setWatcher('select[name="offercode"]', function () {
              startquoteForm.populateSI();
              startquoteForm.populateDOBDatesRange();
            });
            startquoteForm.setWatcher('select[name="si"]', function () {
              startquoteForm.populateDOBDatesRange();
            });

            $('.modal #reQuoteLink').attr("href", Product.data.productLink);

            if(Product.data.buyNowURL != "") {
              $("#quote-apply-now").attr("href", applyBaseURL + Product.data.buyNowURL);
              $(".header-apply-now").attr("href", applyBaseURL + Product.data.buyNowURL);
              $("#quote-apply-now").show();
            }
            else {
              $("#quote-apply-now").hide();
            }
          }
          else {
            console.log(Product.message);
          }
        });
      }
      else if ($('#quote-form input[name="offercode"]').exists()) {
        /* Input hidden offercode */
        options.offerCode = $('#quote-form input[name="offercode"]').val();

        var startquoteForm = new quoteForm('#quote-form', options);
        startquoteForm.init(function(Product){
          /* Process if there are offer codes available */
          if(Product.isAvailable) {
            $(".form-quote-response .productname .form-control-static").text(Product.data.name);
            startquoteForm.populateSI();
            startquoteForm.setWatcher('select[name="si"]', function () {
              startquoteForm.populateDOBDatesRange();
            });

            $('.modal #reQuoteLink').attr("href", Product.data.productLink);

            if(Product.data.buyNowURL != "") {
              $("#quote-apply-now").attr("href", applyBaseURL + Product.data.buyNowURL);
              $(".header-apply-now").attr("href", applyBaseURL + Product.data.buyNowURL);
              $("#quote-apply-now").show();
            }
            else {
              $("#quote-apply-now").hide();
            }
          }
          else {
            console.log(Product.message);
          }
        });
      }
    }

    if ($('#retrieveQuoteRightUtil').exists()) {
        submitRetrieveQuoteForm('#retrieveQuoteRightUtil', actions.quoteinfo);
    }

    if ($('#contactUsModalTier1').exists()) {
      submitContactUs('#contactUsModalTier1', actions.contactus);
    }

    if ($('#navContactMe').exists()) {
      submitContactMe('#navContactMe', actions.contactme);
    }

    if ( $('input[name="iqid"]').exists() ) {
        $('input[name="iqid"]').each(setIqId);
    }

    function setIqId() {
        var $this = $(this);
        var iqId = getIqId(getRandom());

        return $this.attr("value", iqId);
    }

    function getRandom() {
        return Math.random() + "";
    }

    function getIqId(num) {
        return Math.floor(num * 10000000000000);
    }

    if ( $('input[name="dob-dates-min"]').exists() ) {
        $('input[name="dob-dates-min"]').each(getDates);
    }

    if ( $('input[name="dob-dates-max"]').exists() ) {
        $('input[name="dob-dates-max"]').each(getDates);
    }

    function getDates() {
        var $this = $(this);
        var userInput = $this.val();
        var today = new Date();
        var finalDate = (today.getFullYear() - userInput) + "/" + (today.getMonth() + 1) + "/" + today.getDate();
        $this.attr("value", finalDate);
    }

    function submitSessions(formSubmitted) {
      var $forms = $('form');
      var $formSubmitted = $(formSubmitted);
      var $firstName = $formSubmitted.find('input[name="firstname"]').val();
      var $lastName = $formSubmitted.find('input[name="lastname"]').val();
      var $phone = $formSubmitted.find('input[name="phone"]').val();

      $forms.each(function() {
        var $this = $(this);

        if( $this.find('input[name="firstname"]').exists() ) {
          $this.find('input[name="firstname"]').val($firstName);
        }

        if( $this.find('input[name="lastname"]').exists() ) {
          $this.find('input[name="lastname"]').val($lastName);
        }

        if( $this.find('input[name="phone"]').exists() ) {
          $this.find('input[name="phone"]').val($phone);
        }

      });

      $.ajax({
        method: "POST",
        url: actions.userSessionPage,
        data: $formSubmitted.serialize()
      })
      .done(function(resp, status) {
        if (status == "success") {
          console.log("Session variables successfully submitted");
        }
      })
      .fail(function(status) {
        console.log(status)
      })
      .always(function() {
          return;
      })
    }

  if(sessionStorage.getItem("openQuoteModal")){
    $("#quoteModal").modal('toggle');
    sessionStorage.removeItem("openQuoteModal");
  }

  /* Events Listener */
  //$('.modal #requote').on('click', requote);
  $('.modal #reQuoteLink').on('click', function(){ sessionStorage.setItem('openQuoteModal', true); });
  $('[data-back]').click( goBackToForm );
  $('.go-back-state').click( goBackState );
  $("input[name='lang']").val(language);
  $(".plan-details .contact-us").click(function(){ contactMeClick = true; });
  $('#interstitial').on('hidden.bs.modal', function () { contactMeClick = false; });

  window.Parsley.addValidator('phoneNumber', {
    requirementType: 'integer',
    validateNumber: function(value) {
      var positive_checks = new Array(
        /^[0-9]{8}$/g // Must have 8 digits exactly
      );

      var negative_checks = new Array(
        /^[0147]/g, // Must not start with 0,1,4,7
        /^[9]{3}/g, // Must not start with 999
        /^(.)\1*$/g // Must not be all the same number
      );

      for (var i in positive_checks) {
        if (String(value).match(positive_checks[i]) == null) {
          return false;
        }
      }

      for (var i in negative_checks) {
        if (String(value).match(negative_checks[i]) != null) {
          return false;
        }
      }
      return true;
    }
  });

  window.addEventListener('load', function(e) {
        $("#retrieveQuoteMobile .retrieveQuoteMobileUtilErrorMsg").hide();
        $("#retrieveQuoteRightUtil .retrieveQuoteRightUtilErrorMsg").hide();
        $.ajax({
            url: actions.userSessionPage
        })
        .done(function(resp) {
            var $result = $('<div />').append(resp).find('#external-form-response');
            $result.find('span').each(function(i) {
                var $this = $(this);
                storedVars.setVar(i, $this);
            });
            return storedVars.setStoredVarsInForms();
        })
        .fail(function(status){})
        .always(function() {
           return;
        });
    });
}

