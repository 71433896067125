export function updateLanguage(e){
  e.preventDefault();
  var currentLang = $('html').attr('lang');
  var targetLang = $(this).data('lang');
  var targetURL;

  if (targetLang !== currentLang) {
    if (location.pathname === '/') {
      targetURL = '/' + targetLang + location.pathname;
    } else {
      targetURL = location.pathname.replace(currentLang, targetLang);
    }

    targetURL = targetURL + location.search;

    location.assign(targetURL)
  }
}
 function getAsiaTime(){
    var time = new Date();
    var hourTime = time.getUTCHours();
    var minTime = time.getUTCMinutes();
    var asiaTime = hourTime + 8;
    if(asiaTime > 23){
        asiaTime = asiaTime - 24;
    }
    if(asiaTime < 10){
        asiaTime = "0" + asiaTime;
    }
    return asiaTime+":"+minTime;
  }

function officeHours(){
    var time = getAsiaTime();
    var liveChat = window.liveChat;
    if(liveChat){
      var chatTime = liveChat;
      chatTime = chatTime.split('-');
      if(time >= chatTime[0] && time <= chatTime[1]){
          return true;
      }
      else{
          return false;
      }
    }
    else{
      return false;
    }

}
export function tools() {
  $(".tool-trigger").on('click', function(){
    var classList = $(this).attr('class');
    if (classList.includes("search-icon")) {
      $('.search-fixed-right').toggleClass('isShowing');
      $('.language-select-menu').removeClass('isShowing');
      $('.share-menu').removeClass('isShowing');
      $('.share-icon .fa-circle').removeClass('active');
      $('.share-icon.tool-trigger .fa-stack-1x').addClass('fa-share-alt').removeClass('fa-close');
      $('.language-selected .fa-circle').removeClass('active');
      $('.language-selected .language-code').removeClass('hidden');
      $(".chatAlert").hide();
      $(".offline-text").hide();
      $(".disabled-text").hide();
      $('.language-selected .fa-close').addClass('hidden');
      $(this).find($('.fa-circle')).eq(0).toggleClass('active');
      $(this).find($('.fa-stack-1x')).toggleClass('fa-search').toggleClass('fa-close');
    } else if (classList.includes("language-selected")) {
      $('.language-select-menu').toggleClass('isShowing');
      $('.search-fixed-right').removeClass('isShowing');
      $('.share-menu').removeClass('isShowing');
      $('.search-icon .fa-circle').removeClass('active');
      $('.search-icon .fa-stack-1x').addClass('fa-search').removeClass('fa-close');
      $(".chatAlert").hide();
      $(".offline-text").hide();
      $(".disabled-text").hide();
      $('.share-icon .fa-circle').removeClass('active');
      $('.share-icon.tool-trigger .fa-stack-1x').addClass('fa-share-alt').removeClass('fa-close');
      $(this).find($('.fa-circle')).eq(0).toggleClass('active');
      $(this).find($('.language-selected .language-code')).toggleClass('hidden');
      $(this).find($('.fa-close')).toggleClass('hidden');
    } else if (classList.includes("share-icon")) {
      $('.share-menu').toggleClass('isShowing');
      $('.search-fixed-right').removeClass('isShowing');
      $('.language-select-menu').removeClass('isShowing');
      $('.search-icon .fa-circle').removeClass('active');
      $('.search-icon .fa-stack-1x').addClass('fa-search').removeClass('fa-close');
      $('.language-selected .fa-circle').removeClass('active');
      $('.language-selected .language-code').removeClass('hidden');
      $('.language-selected .fa-close').addClass('hidden');
      $(".chatAlert").hide();
      $(".offline-text").hide();
      $(".disabled-text").hide();
      $(this).find($(".fa-circle")).eq(0).toggleClass('active');
      $(this).find($('.tool-trigger .fa-stack-1x')).toggleClass('fa-share-alt').toggleClass('fa-close');
    }else if(classList.includes("chat-icon") && $("#contact-us-disabled").is(":visible")){
      $(".disabled-text").toggleClass("hidden");
      $(".chatAlert").toggleClass("hidden");
      $(".chat-close").toggleClass("hidden");
    }else if(classList.includes("chat-icon") && $("#contact-us-offline").is(":visible")){
         $(".offline-text").toggleClass("hidden");
         $(".chatAlert").toggleClass("hidden");
         $(".chat-close").toggleClass("hidden");
    }
  });

  $('body').click(function(e){
    if($(".share-menu").hasClass("isShowing") && !$(e.target).closest(".tool").hasClass("addthis_toolbox")) {
      $('.share-menu').removeClass('isShowing');
      $('.share-icon .fa-circle').removeClass('active');
      $('.share-icon.tool-trigger .fa-stack-1x').addClass('fa-share-alt').removeClass('fa-close');
    }
    else if($(".language-select-menu").hasClass("isShowing") && !$(e.target).closest(".tool").children().hasClass("language-selected")) {
      $('.language-select-menu').removeClass('isShowing');
      $('.language-icon .fa-circle').removeClass('active');
      $('.language-selected .language-code').removeClass('hidden');
      $('.language-selected .fa-close').addClass('hidden');
    }
    else if($(".tool .search").hasClass("isShowing") && !$(e.target).closest(".tool").children().hasClass("search-icon")) {
      $('.tool .search').removeClass('isShowing');
      $('.tool .search-icon .fa-circle').removeClass('active');
      $('.tool .search-icon.tool-trigger .fa-stack-1x').addClass('fa-search').removeClass('fa-close');
    }
    else if($(".chatAlert").is(":visible")){
      /*$(".chatAlert").hide();
      $(".offline-text").hide();
      $(".disabled-text").hide();*/
    }
  });

  /* Click to Chat Logic */

    //Helper functions 
    //returns the environment
  function getEnvironment(){
    var url = window.location.hostname;
    var pattDev = /hkrinpuw01/g;
    var pattTest = /uatts/g;
    if(pattDev.test(url)){
      return "development";
    }
    else if(pattTest.test(url)){
      return "testing"
    }
    else{
      return "production";
    }

  }
  //returns the product name if product, else returns false
  function isProductPage(){
    var product = $(".product-comparison-product1").text();
    if(product){
      return $("h1").first().text();
    }
    return false;

  }
  //returns the language
  function getLanguage(){
    var language = ($('html').attr("lang") == "en") ? "en_us":"zh_hk";
    return language;
  }
  //returns code and product name... if not product length of array returned is 1
  function getCode(){
    var product = isProductPage();
    var language = getLanguage();
    var environment = getEnvironment();
    var code = "";
    if(product){ // product page
      if(language == "en_us"){ // english product
        switch(environment){
          case "production":
            code = "573p0000000005B"
            break;
          case "testing":
              code = "573p0000000005B";
            break;
          case "development":
            code = "573p0000000005B";
            break;
        }
      }
      else{          // chinese product
        switch(environment){
          case "production":
            code = "573p0000000005G";
            break;
          case "testing":
            code = "573p0000000005G";
            break;
          case "development":
            code = "573p0000000005G";
            break;
        }
      }
      //its a product page
    }
    else{ // customer service page
      if(language == "en_us"){ // english page
        product = "Customer Service";
        switch(environment){
          case "production":
            code = "573p00000000056";
            break;
          case "testing":
            code = "573p00000000056";
            break;
          case "development":
            code = "573p00000000056";
            break;
        }
      }
      else{           // chinese page
        product = "客戶服務";
        switch(environment){
          case "production":
            var code = "573p00000000051";
            break;
          case "testing":
            var code = "573p00000000051";
            break;
          case "development":
            var code = "573p00000000051";
            break;
        }
      }
    }

    return [code,product];

  }

  function chatInitParams(){
    var envi = getEnvironment();
    var params = [];
    if(envi = "development"){
      params.push("https://d.la9cs.salesforceliveagent.com/chat") //push url
      params.push("572p00000000035") //push param1
      params.push("00Dp0000000D5QV") //push param2
    }
    else if(envi == "testing"){
      params.push("https://d.la9cs.salesforceliveagent.com/chat") //push url
      params.push("572p00000000035") //push param1
      params.push("00Dp0000000D5QV") //push param2
    }
    else if(envi == "production"){
      params.push("https://d.la9cs.salesforceliveagent.com/chat") //push url
      params.push("572p00000000035") //push param1
      params.push("00Dp0000000D5QV") //push param2
    }
    return params;
  }
  if ($('#chatTool').exists()) {
      var chatCode = getCode();
      var language = getLanguage();
      var chatParams = chatInitParams();
      $("#liveagent_button_online").attr("onclick","liveagent.startChat('" + chatCode[0] +"')");
      $("#liveagent_button_online").attr("id","liveagent_button_online_" + chatCode[0]);
      $("#liveagent_button_offline").attr("id","liveagent_button_offline_" + chatCode[0]);
      if (!window._laq) {
          window._laq = [];
      }
      if (officeHours() == true) {
          window._laq.push(function() {
              liveagent.showWhenOnline(chatCode[0], document.getElementById('liveagent_button_online_' + chatCode[0]));
              liveagent.showWhenOffline(chatCode[0], document.getElementById('liveagent_button_offline_' + chatCode[0]));
          });

          liveagent.addCustomDetail('Prefer Product', chatCode[1], true);
          liveagent.findOrCreate("Account").map("name", chatCode[1], true, true, true).saveToTranscript('accountId').showOnCreate();
          liveagent.init(chatParams[0], chatParams[1], chatParams[2]);
      } else {
          if ($('#interstitial').exists()) {
              $('#contact-us-offline').toggleClass('hidden');
              $('.start-chat').toggleClass('hidden');
              $('.start-chat-list-item').toggleClass('hidden');
          } else {
              $('#contact-us-disabled').toggleClass('hidden');
              $('.start-chat').toggleClass('hidden');
              $('.start-chat-list-item').toggleClass('hidden');
          }
      }

      if($('#contact-us-offline').is(':visible')) {
          if($('.start-chat').exists()) {
              $('.start-chat').each(function() {
                  $(this).attr('data-toggle','modal');
              });
              $('.start-chat').each(function() {
                  $(this).attr('data-target','#interstitial');
              });
          }
      }
      else{
          if($('.start-chat').exists()) {
              $('.start-chat').each(function() {
                  $(this).click(function(event){
                    event.preventDefault();
                    liveagent.startChat(chatCode[0]);
                  });
              });
          }
      }

    }


  /* End Click to Chat Logic */

  $('.c-footer-navlistlink.carot').on('click', function() {
      $(this).children().toggleClass('hidden');
  });

  $('button.btn-search').on('click', function(e) {
    $('.search-form').submit();
  });

  $('.search-form').on('submit', function(e) {
    e.preventDefault();
    var lang = $('html').attr('lang');
    var term = $.trim($('.search-form').find('#search').val());
    if (term.length) {
      var url = '/' + lang + '/search.html?gsq=' + encodeURIComponent(term);
      location.assign(url);
    }
  });

  $('button.btn-search-mobile').on('click', function(e) {
    $('.search-form-mobile').submit();
  });

  $('.search-form-mobile').on('submit', function(e) {
    e.preventDefault();
    var lang = $('html').attr('lang');
    var term = $.trim($('.search-form-mobile').find('#search').val());
    if (term.length) {
      var url = '/' + lang + '/search.html?gsq=' + encodeURIComponent(term);
      location.assign(url);
    }
  });

  $('.language-select-menu .language-icon, .change-lang-link').off('click').on('click', updateLanguage);

  if ($('html').attr('lang') == 'zh-hant') {
    var addthis_config = addthis_config || {};
    addthis_config.lang = 'zh_HK';
  }

  /*Fix anchor hiding under nav*/
  $('a[href^="#"]').click(function(e){
    if(this.getAttribute("href").charAt(0) == "#"){
      var anchor = $(this).attr("href");
      var setOffset = 100;
      if($('#planAlert').hasClass('isShowing')){
        setOffset += 60;
      }
      if(typeof anchor !== "undefined" && anchor.length>1 && !$(this).attr('data-toggle') && !$(this).attr('data-target') && !$(this).attr('data-slide')){
        e.preventDefault();

          $('html, body').animate({
              scrollTop: $(anchor).offset().top-setOffset
          }, 200);
      }
    }

  });

  /*Copyright Date*/
  var currentDate = new Date,
      currentYear = currentDate.getFullYear();
  $(".footer-wrapper #currentYear").length && $(".footer-wrapper #currentYear").html(currentYear);

  /*Promo match height*/
  $('.tier4-card-wrapper .card-text').matchHeight();
}

