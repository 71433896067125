import { Plans } from '../../../_static/js/plans.js';
import { Product, products, removeStoredProduct, clearProducts, clearVirtualProducts } from '../../../_static/js/product.js';
import '../forms/forms.js';

var isMobile = $(window).width() < 768 ? true : false;

var plansBeingCompared = {};
var plansInCompare = [];

function formatPlanName(name) {
  return name.replace(/ /g, "-").toLowerCase();
}

function showPlanAdded() {
  var $planPromo = $('.medical-plans-card');
  $planPromo.delegate('a[data-action]', 'click', function(event) {
    event.preventDefault();
    $( 'body' ).find($('.compare-plans-wrapper')).toggleClass('isShowing');
  });
}

function toggleCompareCostsForm() {
  $('.compare-costs-form').toggleClass('isShowing');
}

// Generates list items in coverage collapse.
function coverageListGroupItemTpl(coverageObj, index, featureName) {
  var isIncluded = coverageObj.displayChecked;
  var coverageType = '<h4 class="list-group-item-heading">' + coverageObj.subfeatureName + '</h4>';
  var summary = coverageObj.subfeatureDescription;
  var groupName = featureName;

  if(isIncluded == "Yes") {
   /* if (coverageObj.subfeatureName.indexOf('Refund') != -1 || coverageObj.subfeatureName.indexOf('保費') != -1) {
      isIncluded = '<i class="fa fa-2x fa-plus fa-pull-right text-success"></i>';
    } else {*/
      isIncluded = '<i class="fa fa-2x fa-check fa-pull-right text-success"></i>';
    //}
  } else {
    isIncluded = '<i class="fa fa-2x fa-times fa-pull-right text-muted"></i>';
  }

  if (summary != "") {
    summary = '<p class="list-group-item-text">' + summary + '</p>';
  }

  return '<div class="list-group-item" data-mh="'+ groupName +'-feature-' + index +'">' +
           isIncluded +
           coverageType +
           summary +
         '</div>';
}

function simpleListGroupItemTpl(content) {
  return '<div class="list-group-item">' + content + '</div>';
}

// If something is truthy this adds a green check mark
// Else it will be a dark gray X.
function updatePromotionItem($selector, ischecked) {
  $selector.replaceWith( function() {
    if(ischecked == "Yes") {
      return '<i class="fa fa-2x fa-check fa-pull-right text-success">';
    } else {
      return '<i class="fa fa-2x fa-times fa-pull-right text-muted">';
    }
  });
}

function updateIsIncluded(feature) {
  var isIncluded = feature.displayChecked;
  if(isIncluded == "Yes") {
    isIncluded = '<i class="fa fa-2x fa-check fa-pull-right text-success"></i>';
  } else {
    isIncluded = '<i class="fa fa-2x fa-times fa-pull-right text-muted"></i>';
  }
  return isIncluded;
}

function setFeatureDescription(feature) {
  var featureItemDescription;
  if (feature.featureDescription != '') {
    featureItemDescription = feature.featureDescription;
  } else if (feature.subfeatures[0].subfeatureName != '' && feature.subfeatures.length == 1) {
    featureItemDescription = feature.subfeatures[0].subfeatureName;
  } else {
    featureItemDescription = false;
  }
  return featureItemDescription;
}

function featureItemTpl(feature) {
  var featureItemImage;
  if (feature.hasOwnProperty('featureLogo') && feature.featureLogo != '') {
    featureItemImage = '<img src="'+ feature.featureLogo +'" alt=""/>';
  } else if (feature.hasOwnProperty('subfeatureLogo') && feature.subfeatureLogo != '') {
    featureItemImage = '<img src="'+ feature.subfeatureLogo +'" alt=""/>';
  } else {
    featureItemImage = '';
  }
  var featureDescription = setFeatureDescription(feature) ? '<p>' + setFeatureDescription(feature) + '</p>': '';
  var featureIncluded = feature.displayChecked == "Display Options" ? updateIsIncluded(feature.subfeatures[0]) : updateIsIncluded(feature);
  var featureName = feature.featureName ? feature.featureName : feature.subfeatureName;

  var featureItem = '<div class="media">' +
    '<div class="media-left">' + featureItemImage + '</div>' +
    '<div class="media-body media-middle">' +
      '<h4 class="media-heading">'+ featureName +'</h4>' +
      featureDescription +
    '</div>' +
    '<div class="media-right">' + featureIncluded + '</div>' +
  '</div>';

  return '<div class="list-group-item">' + featureItem + '</div>';
}

function cardFootnote(footnote) {
  return '<div class="card-block-section footnote"><div class="plan-footnote">' + footnote + '</div></div>';
}

// Handles updating the plan card with JSON data.
function populatePlanCard(planCardSelector, planDataObj) {
  var $this = planCardSelector;
  var data = planDataObj;
  var $name = $this.find('.plan-name');
  var $enrollmentAge = $this.find('.plan-age');
  var $description = $this.find('.plan-description');
  var $levelPremium = $this.find('.level-premium i');
  var $levelPremiumLi = $this.find('.level-premium');
  var $coverageListGroup = $this.find('.list-group-coverage');
  var $coverageListGroupIcon = $coverageListGroup.find('i');
  var $optionalRiders = $this.find('.optional-riders');
  var $promotionalListItem = $this.find('.promotion-coverage i');
  var $coverageItem = $this.find('.list-group-item-muted');
  var $coverageTitle = $coverageItem.find('.media-heading');
  var $optionalRiderCollapseTrigger = $this.find('.optional-riders.collapse').prev();
  var $productLinkBtn = $this.find('.plan-link');
  var $valueAddedServicesGroup = $this.find('.value-added-services');
  var $valueAddedServicesGroupItem = $this.find('.list-group-value');
  var $valueAddedServicesGroupTitle = $this.find('.value-added-services .media-body h4');
  var coverage = data.features.filter(function(feature) {
    return feature.featureId == "coverage";
  });
  var promotions = data.features.filter(function(feature) {
    return feature.featureId == "promotion";
  });
  var coverageObject = Object.assign({}, coverage[0]);
  var promotionsObject = Object.assign({}, promotions[0]);

  // Update card heading to be same height.
  $('.card.plan.isShowing').each(function(){
    $(this).find('.card-block')
      .first()
      .attr('data-mh', 'card-heading');
  });

  // Reset plan card, will be populated once emptied to avoid duplicate content.
  $coverageListGroup.empty();
  $valueAddedServicesGroupItem.empty();

  // Enable coverage collapse, set Image.
  $coverageItem.find('img').attr('src', coverageObject.featureLogo);

  coverageObject.subfeatures.forEach(function(subfeature, index, array) {
    $coverageListGroup.append(coverageListGroupItemTpl(subfeature, index, 'coverage'));
  });

  promotionsObject.subfeatures.forEach(function(promotion, index, array) {
    $valueAddedServicesGroupItem.append(coverageListGroupItemTpl(promotion, index, 'promotion'));
  });

  // Enable optional riders collapse.
  $optionalRiderCollapseTrigger.attr('href', '#' + data.id + '-optionalriders-collapse');
  $optionalRiderCollapseTrigger.next('.collapse').attr('id', data.id + '-optionalriders-collapse');

  // Basic Plan Name, Age and Description population.
  if (data.name) {
    $name.text(data.name);
  }

  data.enrollmentAge ?
    $enrollmentAge
      .addClass('text-success')
      .text(data.enrollmentAge) :
    $enrollmentAge
      .addClass('hidden');

  data.description ?
    $description
      .html(data.description) :
    $description
      .addClass('hidden');

  data.features.forEach(function(val, ind, arr) {
    if (val.featureName == "Coverage" || val.featureName == "保障" || val.featureId == "coverage") {
      $coverageTitle.text(val.featureName);
    }

    if (val.featureName == "Value Added Services" || val.featureName == "增值服務" || val.featureId == "value-added-services") {
      $valueAddedServicesGroupTitle.text(val.featureName);
    }
  });

  // Loop through the features of the selected product.
  data.features.forEach(function(value, index, array) {
    if (value.featureId == "level-premium") {
      updatePromotionItem($levelPremium, value.displayChecked);
      $levelPremiumLi.replaceWith(featureItemTpl(value));
    } else if (value.featureName == "Policy Term (expiry age)" || value.featureName == "保單年期（保障終止年齡）" || value.featureId == "policy") {
      $this.find('.plan-description').after(addPolicyTerm(value));
    } else {
      return false;
    }
  });

  $('.card.plan.isShowing .list-group-root').each(function() {
    $(this).find('.list-group-item')
      .first()
      .attr('data-mh', 'premium');
  });

  // For plans with policy term.
  // Added beneath description text.
  function addPolicyTerm(policy) {
    var policySubfeature = Object.assign({}, policy.subfeatures[0]);
    return "<p class='card-text plan-policy-description'>" + policy.featureName + ": " + policySubfeature.subfeatureName + "</p>"
  }

  // If there are no optional riders, hide that element.
  if (data.features.indexOf('Optional Riders') == -1) {
    $optionalRiders
      .parents('.card-block-section')
        .addClass('hidden');
  }

  // Update product link button.
  $productLinkBtn.attr('href', data.productLink);

  if (data.footnote != "" && $this.find('.card-block-section.footnote').length == 0) {
    $productLinkBtn.parents('.card-block-section').after(cardFootnote(data.footnote));
  } else if (data.footnote != "") {
    $this.find('.card-block-section.footnote').remove();
    $productLinkBtn.parents('.card-block-section').after(cardFootnote(data.footnote));
  }

  if (document.documentElement.lang == "zh-hant")
    $productLinkBtn.text('了解更多');

  // Update promotion icon and whether it's covered.
  updatePromotionItem($promotionalListItem, data.promotion);

  // Apply data api for match height once a card is populated.
  $.fn.matchHeight._applyDataApi();

}

// Handles updating the alert on the page.
// Only allows three plans to be added.
// If another plan is attempted to be added we show an updated status message.
function updateCompareAlert(alertId, productsArray, warning) {
  var isProductPage = determineConfig();
  var warningPassed = Boolean(warning),
      threePlansAddedWarning = $('html').attr('lang') == 'zh-hant' ?
            "每次可比較3個產品":
            "You can only compare 3 products at once",
      threePlansAddedHtml = '<strong class="text-capitalize">'+ threePlansAddedWarning +'</strong>',
      initialTextAlert = $('html').attr('lang') == 'zh-hant' ?
        '<strong>已加入' + products.length + '個產品至比較</strong>':
        '<strong>' + products.length + ' Products Added To Comparison.</strong>',
      plansAdded;
  if($(".tier-3-btn-row").exists() && isMobile){
     $( alertId ).addClass('isShowing');
     var offset = $(".tier-3-btn-row").css("height");
     $(alertId).css("padding-top",offset);
     if(isProductPage.product.one != ""){
        $( alertId + " .btn-compare" ).addClass("hidden");
     }
  }
  else{
     $( alertId ).addClass('isShowing');
     if(isProductPage.product.one != ""){
        $( alertId + " .btn-compare" ).addClass("hidden");
     }
  }
  if (products.length === 0) {
    $( alertId ).removeClass('isShowing');
    return;
  }else if(isProductPage.product.one != "" && products.length == 1){
    $( alertId ).removeClass('isShowing');
    return;
  }

  if ( products.length <= 3 && warningPassed == false) {
    $( alertId + ' .alert-status strong').removeClass('text-capitalize').html(initialTextAlert);
  } else if (warningPassed) {
    $( alertId + ' .alert-status strong').html(threePlansAddedHtml);
  }

  plansAdded = products.length;
}

function generateNewPlan(plan) {
  var findPlan = Plans.commonMethods.lookupPlan(plan);

  if(findPlan) {
    var _tmp = new Product(findPlan.name);
    _tmp.setData(findPlan)
  }

  return _tmp;
}

// Shows/Hides add to compare button
function updatePlanCard(planId) {
  var $allPlanCards = $('.add-plan');

  $allPlanCards.each(function() {
    if(planId == $( this ).data('plan')) {
      $( this ).toggleClass('hidden')
      $( this ).parents('.card-link').find('.remove-plan').toggleClass('hidden');
    }
  });
}



function showPlanSelected(selector, plan) {
  var planObj = plan;

  // event fired at dropdown
  //still works mobile
  if (!$(selector.target.offsetParent).hasClass('card') && !$(selector.target.offsetParent).hasClass('fa-stack')) {
    var $dropdownRoot = $(selector.target).parents('.plan-compare-dropdown');
    updatePlanCard(planObj.id);
  } else {
    var $dropdownRoot = isMobile ?
      $('.plan-select-group .plan-compare-dropdown').not('.plan-set').first() :
      $('.results-wrapper .plan-compare-dropdown').not('.plan-set').first();
    // $dropdownRoot = $dropdownRoot.first();
  }
  var $planCard;

  if (isMobile) {
    $dropdownRoot
      .toggleClass('plan-set')
      .prev()
        .children('strong')
          .text(planObj.name);

    $dropdownRoot.prev().addClass("disabled");
    $planCard = $('.results-wrapper').find('.plan').not('.plan-present').first();
    $planCard.addClass('plan-present').toggleClass('isShowing');
  } else {
    $planCard = $dropdownRoot.closest('.card').next();
    $dropdownRoot
      .toggleClass('plan-set')
      .closest($('.card'))
        .toggleClass('hidden')
      .next()
        .toggleClass('isShowing');
  }

  // Populate plan card with plan data.
  populatePlanCard($planCard, planObj);

}

function removeAll() {
  hadToPop = false;
  var pageData = determineConfig();
  $('.plan').each(function(index){
    var $this = $( this );
    if (pageData.product.one == '') {
      if ($this.hasClass('isShowing') && !isMobile) {
        $this.removeClass('isShowing')
          .prev()
          .toggleClass('hidden');
        $this.find('.list-group-value').empty();
        $this.find('.list-group-coverage').empty();
      } else if($this.hasClass('isShowing') && isMobile) {
        $this.removeClass('isShowing');
        $this.removeClass('plan-present');
        $this.find('.list-group-value').empty();
        $this.find('.list-group-coverage').empty();
      }
    } else {
      if ($this.hasClass('isShowing') && !isMobile && index != 0) {
        $this.removeClass('isShowing')
          .prev()
          .toggleClass('hidden');
        $this.find('.list-group-value').empty();
        $this.find('.list-group-coverage').empty();
      } else if($this.hasClass('isShowing') && isMobile && index != 0) {
        $this.removeClass('isShowing');
        $this.removeClass('plan-present');
        $this.find('.list-group-value').empty();
        $this.find('.list-group-coverage').empty();
      }
    }
  });

  // 6 dropdowns, on product pages remove plan set from all except this at index 0.
  $('.plan-compare-dropdown').each(function(index) {
    // For mobile
    if ((index != 0 || index != 3) && pageData.product.one) {
      $(this).removeClass('plan-set');
    } else if (pageData.product.one == '') {
      if ($(this).hasClass('plan-set'))
        $(this).siblings('.product-select-dropdown').children('strong').text()
        $(this).removeClass('plan-set');
    }
  });

  // Only on non product pages.
  if( $('[data-remove-plan]').length > 0 ) {
    $('[data-remove-plan]').not('.hidden').each(function() {
      $(this).toggleClass('hidden').prev().toggleClass('hidden');
    });
  }

  // (!cutUrl(location.href).product) - not on a product page.
  // If not on product page remove alert.
  if (!pageData.product.one)
    $("#planAlert").removeClass('isShowing');

  var dropdownText = document.documentElement.lang == "en" ? "Select a product to compare" : "選擇要比較的產品";
  // clearProducts will be an array with only the product object of the current product page.
  updateCompareAlert('#planAlert', clearProducts());
  if (pageData.product.one != '' && isMobile) {
    $('.plan-select-group .plan-compare-dropdown').not(':first')
      .siblings('.product-select-dropdown')
        .children('strong')
        .text(dropdownText);
        $('.plan-select-group .plan-compare-dropdown').not(':first').siblings('.product-select-dropdown').removeClass("disabled");
  } else {
    $('.plan-compare-dropdown').not('.plan-set')
      .siblings('.product-select-dropdown')
        .children('strong')
        .text(dropdownText);
        $('.plan-compare-dropdown').not('.plan-set').siblings('.product-select-dropdown').removeClass("disabled");
  }


  //toggleCompareCostsForm();
  $('.compare-costs-form').removeClass('isShowing');

  /*if(pageData.product.one) {
    sessionStorage.removeItem(pageData.product);
  } else {
    sessionStorage.removeItem(pageData.category);
  }*/

  clearProducts();
  //plansInCompare = clearVirtualProducts(plansInCompare);

  // Hides list item in dropdown.
  $(".plan-compare-dropdown [data-plan]").each(function() {
    var $this = $(this);
    var $dropdownPlanValue = $this.data('plan');
    if ($this.parents('.hk-dropdown-list-item').hasClass('hidden') && $dropdownPlanValue !== pageData.product.one) {
      $this.parents('.hk-dropdown-list-item').toggleClass('hidden');
    }
  });

  // Remove matchHeight when on a Category page and not in mobile.
  if (!isMobile && !pageData.product.one) {
    $('[data-mh="premium"]').matchHeight({remove: true});
    $('[data-mh="card-heading"]').matchHeight({remove: true});
  }

  $('.plan-policy-description').remove();

  // Hide quote response when removeall.
  if($('#quoteCompareCostReq').hasClass("hidden")) {
    $('#quoteCompareCostReq').removeClass('hidden');
  }
  if(!$('#quoteCompareCostResp').hasClass("hidden")) {
    $('#quoteCompareCostResp').addClass('hidden');
  }
  $('#compareCostClone').remove();

}

var hadToPop = false;

function addPlanToProducts(plan) {
  var planObj;
  if (products.length <= 3) {
    if (plan instanceof Product == false) {
      planObj = Plans.commonMethods.lookupPlan(plan);
      products.push(planObj);
      if (products.length > 3) {
        products.pop();
        hadToPop = true;
      }
    }
    return planObj;
  }
}

function resetCard(card) {
  if (card != undefined) {
    var $cardToHideTitle = $('.card-title:contains('+ card.name +')');
    var $card = $cardToHideTitle.parents('.plan');
    // Appended value added services and coverage need to be removed.
    $card.find('.list-group-coverage').empty();
    $card.find('.list-group-value').empty();
  } else {
    var $card = $('.plan');
    $card.find('.list-group-coverage').empty();
    $card.find('.list-group-value').empty();
  }
}

function triggers(){
 // Function that handles determining which plan has been selected.
 $("[data-plan]").on('click', function(event) {
    event.preventDefault();

    var selectedPlan = $(this).data('plan');
    selectedPlan = addPlanToProducts(selectedPlan);

   // For virtual tracking
   //plansInCompare.push({ plan: selectedPlan.id, index: $(this).closest(".plan-select").index() });

     //Hide selected plan from DropDown
    $(".plan-compare-dropdown [data-plan='"+ selectedPlan.id + "']").parents('.hk-dropdown-list-item').addClass("hidden");
    //plansInCompare.push(selectedPlan.id);
    if (isMobile) {
      console.log(isMobile);
      var plan = Plans.commonMethods.lookupPlan(selectedPlan)
      $(this).parents('.dropdown-menu').siblings('.product-select-dropdown').children('strong').text(selectedPlan.name);
      $(this).parents('.dropdown-menu').siblings('.product-select-dropdown').addClass("disabled");

    }

    // Show the user the plan was selected
    // If there are 3 products or less - Update Alert, Populate card and in the background update our state to handle the plan being added.
    // Else we need to show warning, provide updateAlert with true.
    if (hadToPop == false && products.length <= 3) {
      showPlanSelected(event, selectedPlan);
      var isProductPage = determineConfig();
      if(isProductPage.product.one != "" && products.length > 1){
        updateCompareAlert('#planAlert', products);
        $('.compare-costs-form').addClass('isShowing');
        $('#compare-costs #costs-datepicker').datepicker('option', 'yearRange', '-100:+0');
      }
      else if(isProductPage.product.one == ""){
        updateCompareAlert('#planAlert', products);
      }
    } else if(hadToPop && products.length <= 3) {
      updateCompareAlert('#planAlert', products, true);
    } else {
      updateCompareAlert('#planAlert', products);
    }

    if (hadToPop == false) {
      var $addToCompareBtnParent = $( this ).parents('.card-link');
      $addToCompareBtnParent.find('a').toggleClass('hidden');
    }

  });

  $('[data-remove-plan]').click(function(event) {
    event.preventDefault();
    var selectedPlan = $(this).data('removePlan'),
        $btnParent = $( this ).parents('.card-link'),
        cardToHide = Plans.commonMethods.lookupPlan(selectedPlan),
        $cardToHideTitle = $('.card.plan.isShowing .card-title:contains('+ cardToHide.name +')'),
        $cardToHideDropdown = $cardToHideTitle.parents('.plan').prev();

    $(".plan-compare-dropdown [data-plan='"+ cardToHide.id + "']").parents('.hk-dropdown-list-item').removeClass("hidden");
    $cardToHideTitle.parents('.plan').removeClass('isShowing').prev().toggleClass('hidden');
    $cardToHideDropdown.find('.plan-set').toggleClass('plan-set');

    if (isMobile) {
      $cardToHideTitle.parents('.plan-present').removeClass('plan-present').prev('.plan-select').removeClass('hidden');
      $('.plan-set').siblings('button').find('strong').each(function() {
        var $this = $(this);
        var dropdownText = document.documentElement.lang == "en" ? "Select a product to compare" : "選擇要比較的產品";
        if ($this.text() == cardToHide.name){
          $this.text(dropdownText);
          $this.parent().removeClass("disabled");
          $this.parent().removeClass('plan-set');
        }

      })
    }

    products.forEach(function(v, i, a) {
      if(v.id == selectedPlan) {
        a.splice(i, 1);
      }
    });
    updateCompareAlert('#planAlert', products);
    $btnParent.find('a').toggleClass('hidden');

    if ( $('.compare-costs-form').hasClass('isShowing') ) {
      $('.compare-costs-form').removeClass('isShowing');
    }

    if (products.length < 3) {
      hadToPop = false;
    }

    // Remove content populated in plan card. Plan/Product object is passed.
    resetCard(cardToHide);

  });
}

function compare() {

  var $planOption = $('[data-plan]');
  var $planDropdown = $('.hk-dropdown:not(.personalizer-dropdown)');
  var $productSelectDropdown = $('.product-select');
  var width = $(window).width();

  // Change some values based on language for chinese.
  if (document.documentElement.lang == "zh-hant") {
    $('.remove-plan-text').text(" 從計劃比較移除");
    $('.btn-remove .remove-btn-text').text('從計劃比較移除');
    $('.btn-compare').text('加入至計劃比較');
    $('#planCompare .banner-headline').text('計劃比較');
    $(".monthly-premium").siblings().text("每月保費");
    $(".annual-premium").siblings().text("年度保費");
    $('.product-select-dropdown').children('strong').text("選擇要比較的產品");
    $("#quoteCompareCostResp .compare-plans-footer-heading").text("請聯絡我們獲取真正且合個人需要的報價，並獲知更多最新推廣");
    $("#quoteCompareCostResp #quote-again").html("<i class=\"fa fa-chevron-left text-white \"></i> 重新報價");
  }

  // Handles horizontally scrolling plan compare tool for xs, sm, up to md (992px);
  $productSelectDropdown.on({
    "show.bs.dropdown": function (event) {
      if ( width < 992 ) {
        var $targetArea = $(event.relatedTarget).parents('.product-select');
        $targetArea.addClass('is-open');
      }
    },
    "hide.bs.dropdown": function(event) {
      if ( width < 992 ) {
        var $targetArea = $(event.relatedTarget).parents('.product-select');
        $targetArea.removeClass('is-open');
      }
    }
  });

  // Expands collapsable elements on all cards at the same time.
  var expandAll = function(targetEle) {
    var $allPlanCards = $('.plans .plan, .products .product');
    var targetEleClassList = targetEle.attributes.getNamedItem('class').nodeValue;

    $allPlanCards.each(function() {
      $( this )
        .find($('.'+targetEleClassList.split(' ')[1]))
        .next()
        .collapse('toggle');
    });
  };

  // Call collapse on all of the same collapses.
  $('.plans, .products').on('click', '[data-toggle="collapse"]', function(event) {
    event.preventDefault();
    return expandAll(event.currentTarget);
  });

  // Removes all selected plans in compare.
  $('[data-action="removeall"]').on('click', removeAll);

  // Compare Costs Quote
  var actions = {
    contactme: actionBaseURL + '/eSalesHKWS/ContactMe.do?',
    contactus: actionBaseURL + '/eSalesHKWS/Contactus.do?',
    productquote: actionBaseURL + '/eSalesHKWS/GetQuote.do?',
    emailquote: actionBaseURL + '/eSalesHKWS/SendQuote.do?',
    quoteinfo: actionBaseURL + '/eSalesHKWS/getquoteinfo.do?',
    userSessionPage: location.protocol + '//' + location.host + '/' + locale + '/user-session.page'
  };

  if ($('#compare-costs').exists()) {
    submitCompare('#compare-costs', actions.productquote);
  }

  var formatMoney = function (value) {
    // Convert the value to a floating point number in case it arrives as a string.
    var numeric = parseFloat(value);
    // Specify the local currency.
    return numeric.toLocaleString('HKD', { style: 'currency', currency: "HKD", minimumFractionDigits: 0, maximumFractionDigits: 2 });
  };

  function goBackState() {
    var state = $(this).attr("data-go-back");
    $("#compareCostClone").remove();
    if($("#" + state + 'Err').hasClass("hidden") == false) {
      $("#" + state + 'Err').addClass('hidden');
    }
    if($("#" + state + 'Resp').hasClass("hidden") == false) {
      $("#" + state + 'Resp').addClass('hidden');

      if ($(window).width() < 768)
        $("#" + state + 'Resp').find('.quotes').removeClass('hidden');
    }
    $("#" + state + 'Req').removeClass('hidden');
  }

  function generateHiddenVals(ele, form) {
    var $this = $(ele);
    var $form = form;
    var product = products.filter(function(prod) {
      return prod.name == $this.find('.card-title').text();
    });
    var productObject = Object.assign({}, product[0]);
    var fields = [
      "campaignCode",
      "offercode",
      "si"
    ];
    var fieldObj = {};

    fields.forEach(function(value, index, array) {
      var $fieldToUpdate = $form.find('input[name="'+ value +'"]');
      if (value == 'campaignCode') {
        console.log(productObject["campaignCode"]);
        if ($form.find('input[name="campaigncode"]').length > 0 && productObject["campaignCode"] != "") {
          $form.find('input[name="campaigncode"]').val(productObject["campaignCode"]);
        } else if ( productObject["campaignCode"] == "" ) {
          var hardCodedCampaignCode = $("#contactMe").find('input[name="campaigncode"]');
          $form.find('input[name="campaigncode"]').val(hardCodedCampaignCode.val());
        } else {
          $fieldToUpdate.val(productObject["campaignCode"]);
        }
        fieldObj[value] = productObject["campaignCode"]
      } else if (value == 'offercode') {
        $fieldToUpdate.val(productObject["compareOfferCode"]);
        fieldObj['offerCode'] = productObject["compareOfferCode"];
      } else if (value == 'si') {
        $fieldToUpdate.val(productObject["compareSi"]);
        fieldObj[value] = productObject["compareOfferCode"];
      }
    });

    return fieldObj;
  }

  function submitCompare(form, action) {
    $(form + ' #costs-datepicker').datepicker('option', 'yearRange', '-100:+0');

    $(form).parsley().on('field:validated',function () {
      $('.parsley-error').next().removeClass('hidden');
      $('.parsley-success').next().addClass('hidden');
    }).on('form:submit', function (e) {

      $(form + ' button[type="submit"]').prop("disabled", true);
      $(form + ' button[type="submit"]').prepend('<i class="fa fa-spinner fa-lg fa-spin form-submit" style="color: #ffffff;"></i> ');

      $('.go-back-state').click( goBackState );

      var ele = $(this.$element),
          deferreds = [],
          result = {
            data: [],
            success: true
          };

      //$('.card.plan.isShowing').each(function(index, element) {
      $('.card.plan').each(function(index, element) {
        var fields = generateHiddenVals(element, $(form));

        $(form + ' input[name="dob-dates-min"]').val("");
        $(form + ' input[name="dob-dates-max"]').val("");

        //console.log(products);
        //console.log(fields.offerCode);
        var formParams = $(form).serialize();
        $.each(products, function(key, product) {
          if(fields.offerCode == product.compareOfferCode && product.compareIncome != "") {
            formParams += "&income=" + encodeURIComponent(product.compareIncome);
            return false;
          }
        });

        //var uri = decodeURIComponent($(form).serialize());
        var uri = decodeURIComponent(formParams);

        if($(this).hasClass("isShowing"))
          deferreds.push($.get(action + uri));
        else
          deferreds.push(null);
      });

      $.when.apply($, deferreds).done(function(){
        //if(result.success) {
          if ($(window).width() < 768) {
            $('.card-deck.plans.scrollable').after('<div id="compareCostClone" class="card-deck scrollable"></div>');
          }
          $.each(arguments, function (index, response) {
            if(response != null) {
              if ($(".quotes-summary .quote:eq(" + index + ")").hasClass("quote-summary-empty")) {
                $(".quotes-summary .quote:eq(" + index + ")").removeClass("quote-summary-empty");
              }

              var res = response[0];

              if (res.status == 'success') {
                $('.quote-summary').removeClass('hidden');

                if ($(".quotes-summary .quote:eq(" + index + ") .table-quote-result").hasClass("hidden")) {
                  $(".quotes-summary .quote:eq(" + index + ") .table-quote-result").removeClass("hidden");
                }
                if ($(".quotes-summary .quote:eq(" + index + ") .table-quote-error").hasClass("hidden") == false) {
                  $(".quotes-summary .quote:eq(" + index + ") .table-quote-error").addClass("hidden");
                }
                $(".quotes-summary .quote:eq(" + index + ") .monthly-premium").text(formatMoney(res.BPMonthlyPrem));
                $(".quotes-summary .quote:eq(" + index + ") .annual-premium").text(formatMoney(res.BPAnnualPrem));
              }
              else {
                var $quoteSummary = $(".quotes-summary .quote:eq(" + index + ")");
                var $quoteSummaryTable = $quoteSummary.find('.table-quote-error');
                var quoteSummaryMessage = document.documentElement.lang == "en" ? "Please contact us to learn more about this product." : "請聯絡我們以了解更多";
                var quoteSummaryTableTpl = '<tbody><tr><td>' + quoteSummaryMessage + '</td></tr></tbody';

                $quoteSummaryTable.empty();
                $quoteSummaryTable.html(quoteSummaryTableTpl);
                $quoteSummaryTable.find('td:first-child').css('text-align', 'left');

                // Should show summary no matter what.
                if ($(".quotes-summary .quote:eq(" + index + ") .table-quote-result").hasClass("hidden") == false) {
                  $(".quotes-summary .quote:eq(" + index + ") .table-quote-result").addClass("hidden");
                }
                if ($(".quotes-summary .quote:eq(" + index + ") .table-quote-error").hasClass("hidden")) {
                  $(".quotes-summary .quote:eq(" + index + ") .table-quote-error").removeClass("hidden");
                }
              }
            }
            else {
              if ($(".quotes-summary .quote:eq(" + index + ")").hasClass("quote-summary-empty") == false) {
                $(".quotes-summary .quote:eq(" + index + ")").addClass("quote-summary-empty");
              }
              if ($(".quotes-summary .quote:eq(" + index + ") .table-quote-result").hasClass("hidden") == false) {
                $(".quotes-summary .quote:eq(" + index + ") .table-quote-result").addClass("hidden");
              }
              if ($(".quotes-summary .quote:eq(" + index + ") .table-quote-error").hasClass("hidden") == false) {
                $(".quotes-summary .quote:eq(" + index + ") .table-quote-error").addClass("hidden");
              }
            }
          });

          if ($('#quoteCompareCostReq').hasClass("hidden") == false) {
            $('#quoteCompareCostReq').addClass('hidden');
          }
          if ($('#quoteCompareCostResp').hasClass("hidden")) {
            $('#quoteCompareCostResp').removeClass('hidden');
          }
          if ($(window).width() < 768) {
            var $quotes = $('.card-deck.quotes').last().clone()
            $quotes.appendTo($('#compareCostClone'));

            $('.card-deck.quotes').last().addClass('hidden');
            $('#compareCostClone .quote-summary').matchHeight();
            //So results scroll with cards
            var scrollable = $('.scrollable');

            scrollable.scroll(function() {
              scrollable.scrollLeft($(this).scrollLeft());
            });
          }

        $(form + ' .form-submit').remove();
        $(form + ' button[type="submit"]').prop("disabled", false);
      });

      return false;

    });

  }
}

export function compareNow() {
  $('[data-action="compare"]').click(function(event) {

    /* START - Virtual Tracking */
    //var virtualLanguage = ($('html').attr("lang") == "en") ? "EN" : "ZN";
    var obj = {
      'event': "CompareNowButton"
    };
    $.each(products, function(key, value) {
      key++;
      obj["Product" + key] = value.id;
    });

    if (products.length == 2){
      obj["Product3"] = "";
    }


    dataLayer.push(obj);
    /* END - Virtual Tracking */

    $('html, body').animate({
        scrollTop: $("#planCompare").offset().top - 100
    }, 800);
    if ( $('.compare-costs-form').hasClass('isShowing') == false ) {
      //toggleCompareCostsForm();
      $('.compare-costs-form').addClass('isShowing');
      $('#compare-costs #costs-datepicker').datepicker('option', 'yearRange', '-100:+0');
    }
  });
}

function load() {
  if ( $('.medical-plans-card .card').length > 0 ) {
    $('.medical-plans-card .card .card-block > .media').matchHeight();
  }

  if($(".plan-compare-dropdown ul").length > 0 && Plans.hasOwnProperty('category')){
    compare();
    resetCard();
    populateDropDowns();
    triggers();
    var current = determineConfig();
    if(current.product.one){
      if (isMobile) {
        $(".plan-compare-dropdown:eq(0) ul [data-plan='"+ current.product.one + "']").trigger('click');
        $(".plan-compare-dropdown:eq(0)").siblings().addClass("disabled");
      } else {
        if(Plans.category.data.length == 2){
          $(".plan-compare-dropdown:eq(2) ul [data-plan='"+ current.product.one + "']").trigger('click');
        }else{
          $(".plan-compare-dropdown:eq(3) ul [data-plan='"+ current.product.one + "']").trigger('click');
        }
      }
    }
  }
}

// Function to find category and product based off passed url or the current location (default)
export function cutUrl(url){
  if(!url){
     var cat = location.pathname.split('/').slice(-2,-1).toString();
     var prod = location.pathname.split('/').slice(-1).toString();
     prod = prod.replace(".html",""); // only for local development
  }else{
     var cat = url.split('/').slice(-2,-1).toString();
     var prod = url.split('/').slice(-1).toString();
     prod = prod.replace(".html",""); // only for local development
  }
   return{
      category: cat,
      product: prod
   }
}

export function determineConfig(){
  var cat = $(".product-comparison-config .product-comparison-category").text();
  var prod = {one:$(".product-comparison-config .product-comparison-product1").text(),two:$(".product-comparison-config .product-comparison-product2").text()};

  return{
    category: cat,
    product:prod
  }

}

export var pageData = determineConfig();

function populateDropDowns(clear){
  if(clear == true){
  }else{
    var listItem = "";
    for(var index in Plans.category.data){
      // Get the id... then the name
      listItem += "<li class=\"hk-dropdown-list-item\"><i class=\"fa-li fa fa-chevron-right text-blue-logo\"></i><a data-plan=\"" + Plans.category.data[index].id + "\" class=\"text-capitalize plan-option\"><strong>" + Plans.category.data[index].name.toUpperCase() + "</strong></a></li>";
    }
    $(".plan-compare-dropdown ul").append(listItem);
  }
}

export function getPlanData() {
  // Helps determine which path and file name to use for our AJAX request.
  var planDataJson = {
    path: window.location.href.indexOf('3000') > 0 ? '/static/json/' : '/iwov-resources/json/',
    lang: $('html').attr('lang') === 'en' ? 'en-US/' : 'zh-hant/',
    file: 'products.json'
  };

  function Category(name, products) {
    this.name = name;
    this.products = products;
  }

  $.ajax({
    dataType: 'json',
    url: planDataJson.path + planDataJson.lang + planDataJson.file
  })

    .done(function(response) {
      var categories = response.category;
      var currentProduct = [];
      //var current = cutUrl();
      var current = determineConfig();
      for (var category in categories) {
        if(current.category == categories[category].name){
          //only push data of category page we are on
          currentProduct.push(new Category(categories[category].name, categories[category].products));
        }
      };
      //Add the data to the local plans obj
      Plans.category = {};
      Plans.category.name = currentProduct[0].name;
      Plans.category.data = currentProduct[0].products;
      //Put the json in order
      Plans.category.data.sort(function(a,b){
        return (a.name > b.name);
      });
      // If the amount of plans in a given category is two than we hide the last dropdown.
      if (Plans.category.data.length == 2) {
        var $quoteSummaries = $('.quotes-summary .quotes').find('.quote');
        $quoteSummaries.last().remove();
        $('.plan-select.hidden-xs:last, .plan-select.visible-xs:last').remove();
        $('.plans .plan').last().remove();
      }
    })

    .fail(function() {
      console.log("Error");
    })

  .always(function(response) {
    //add id to product obj for plan triggers
    if(Plans.category.data) {
      Plans.category.data.forEach(function(v, i, a) {
        var info = v.productCode;
        var $t = $('[href="'+info+'"]');
        v.id = info;
        //console.log(info.product);
        if ($t.exists()) {
          var $cardFooterLinks = $t.parents('.card-link').siblings();
          $cardFooterLinks.find('.add-plan').data('plan', info);
          $cardFooterLinks.find('.remove-plan').data('removePlan', info);
        }
      });
      load();
    } else {
      //throw new Error("Plan Data not found.");
      console.log("Plan Data not found.");
    }
  });
}

var actionBaseURL = location.protocol + '//' + location.host;
// var actionBaseURL = 'https://hkrappuw001.asia.intl.cigna.com:9443';
var locale;

if (document.documentElement.lang == 'en') {
  locale = 'hk_en';
} else {
  locale = 'hk_zh-hant';
}

var language = ($('html').attr("lang") == "en") ? "en_US":"zh_HK";
